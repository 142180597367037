import React, { useState, useEffect } from 'react';
import { ControlState, IOwnerState } from '../types';
import { IProps } from './types';
import {
  Control,
  ControlLabel,
} from '../styles';
import {
  IconButton,
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
  Stack,
  Button,
} from '@mechis/elements';
import { getColorByState } from '../Selections';
import SpeedIcon from '@mui/icons-material/Speed';
import {
  EventByIdDocument,
  MaintenanceIntervalsByDeviceDocument,
  useActualOdometerStateByDeviceIdQuery,
  useUpdateEventOdometerStateMutation,
} from '@state/mechis-backend/generated/schema';
import Odometer from '@mechis/blocks/OdometerBlock';
import snackbarCacheControl from '@state/mutations/snackbar';
import useTech, { UnitType } from '@hooks/useTech';

const MutableOdometer: React.FC<IProps> = ({ eventDateAndOdometer, eventId }) => {
  const [ openOdometer, setOpenOdometer ] = useState(false);
  const [ odometerState, setOdometerState ] = useState<ControlState>('default');
  const [ odometerValue, setOdometerValue ] = useState<number>(0);
  const [ initialOdometerValue, setInitialOdometerValue ] = useState<number>(0);
  const { techId, getTechUnit } = useTech();
  const { setSnackbar } = snackbarCacheControl;

  useEffect(() => {
    if (eventDateAndOdometer !== undefined) {
      setOdometerValue(eventDateAndOdometer.event?.stateOdometer ?? 0);
      setOdometerState('filled');
    }
  }, [ eventDateAndOdometer ]);
  
  const {
    data: actualOdometerData,
    loading: actualOdometerDataLoading,
    error: actualOdometerDataError,
  } = useActualOdometerStateByDeviceIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      deviceId: techId,
    },
  });

  const [ updateEventOdometerStateMutation ] = useUpdateEventOdometerStateMutation({
    onCompleted: () => setSnackbar('success', 'EVENT_UPDATE_SUCCESSFUL'),
    onError: () => setSnackbar('error','EVENT_UPDATE_ERROR'),
    refetchQueries: [
      {
        query: EventByIdDocument,
        variables: {
          eventId: Number(eventId),
        },
      },
      {
        query: MaintenanceIntervalsByDeviceDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });

  useEffect(() => {
    if (!actualOdometerDataLoading && !actualOdometerDataError) {
      setInitialOdometerValue(actualOdometerData?.actualOdometerCalculated ?? 0);
    }
  }, [ actualOdometerData, actualOdometerDataLoading, actualOdometerDataError ]);

  const ownerState: IOwnerState = {
    state: odometerState,
  };

  const onConfirm = async () => {
    if (odometerValue === 0) {
      setOdometerState('missing');
    } else {

      await updateEventOdometerStateMutation({
        variables: {
          event: {
            id: Number(eventId),
            stateOdometer: odometerValue,
          },
        },
      });

      setOdometerState('filled');
      setOpenOdometer(false);
    }
  };

  const onClose = () => {
    setOpenOdometer(false);
    if (odometerValue === 0) {
      setOdometerState('missing');
    } else {
      setOdometerState('filled');
    }
  };
  
  return (
    <>
      <Control>
        <IconButton
          size="small"
          color={getColorByState(odometerState)}
          isSquared
          onClick={() => setOpenOdometer(true)}
        >
          <SpeedIcon />
        </IconButton>
        <ControlLabel ownerState={ownerState}>{`${odometerValue}${getTechUnit(UnitType.Odometer, true)}`}</ControlLabel>
      </Control>
      
      <Dialog
        onClose={onClose}
        open={openOdometer}
        maxWidth="xs"
      >
        <DialogContent>
          <Box textAlign="center">
            <DialogTitle fontSize={'1.3rem !important'} color={'primary'}>
             Tachometr
            </DialogTitle>
          </Box>
          <Box mt={2} mb={4}>
            <Odometer
              odometerValue={odometerValue}
              setOdometerValue={(value: string) => setOdometerValue(parseInt(value))}
              currentOdometerValue={initialOdometerValue}
              odometerState={odometerState}
            />
          </Box>
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                disabled={odometerState === 'missing' && odometerValue === 0}
                onClick={onConfirm}
              >
                Potvrdit
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MutableOdometer;
