import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box,
  Typography,
  Paper,
  Button,
  Skeleton,
} from '@mechis/elements';
import SimpleTable from '@mechis/blocks/SimpleTable';
import { useTranslation } from 'react-i18next';
import RecordWidget from '@mechis/sections/RecordWidget';
import { useDeviceBasicInformationQuery } from '@state/mechis-backend/generated/schema';
import { tableOutputString } from '@utilities/app';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from './ControlMenu';
import AnalysisWidget from '@mechis/sections/AnalysisWidget';
import MaintenancePlanWidget from '@mechis/sections/MaintenancePlanWidget';
import useTech from '@hooks/useTech';
import { AttachmentsContent } from '@mechis/sections/Attachments';

const TechDetail: FC = () => {
  useHeader({
    headline: 'appBar.headline.deviceCard',
    widget: (
      <Selections>
        <ControlMenu />
      </Selections>
    ),
  });

  const { techId } = useTech();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useDeviceBasicInformationQuery({
    variables: {
      deviceId: techId,
    },
  });

  if (error) {
    navigate('/app');
  }

  const device = data?.device;

  return (
    <div>
      {loading ? (
        <Skeleton variant="rounded" sx={{ height: '274px', borderRadius: '12px' }} />
      ) : (
        <SimpleTable
          title="Údaje"
          data={[
            { label: 'Kategorie', value: t(`deviceCategories.${device?.deviceCategory?.name}`)},
            { label: 'Vlastní název', value: tableOutputString(device?.name)},
            { label: 'Značka', value: tableOutputString(device?.manufacturer)},
            { label: 'Model', value: tableOutputString(device?.model)},
          ]}
          ctas={[
            { 
              title: 'Zobrazit více údajů',
              color: 'secondary',
              onClick: () => navigate(`/app/${techId}/tech-detail/info`),
            },
          ]}
        />
      )}

      <RecordWidget />
      
      {/* Fotografie */}
      <Box my={2}>
        <Typography variant="sectionHeadline">Přílohy</Typography>
        <AttachmentsContent variant="attachmentsWidget" />
        <Box textAlign="right" mt={1}>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={() => navigate(`/app/${techId}/tech-detail/attachments`)}
          >
            Více Příloh
          </Button>
        </Box>
      </Box>

      {/* Plány údržby */}
      <Box my={2}>
        <Typography variant="sectionHeadline">{t('techDetail.maintenancePlan.title')}</Typography>
        <MaintenancePlanWidget />
        <Box textAlign="right" mt={1}>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={() => navigate(`/app/${techId}/tech-detail/maintenance-plan`)}
          >
            {t('techDetail.maintenancePlan.moreInfo')}
          </Button>
        </Box>
      </Box>

      {/* Analýzy */}
      <Box my={2}>
        <Typography variant="sectionHeadline">{t('techDetail.analysis.title')}</Typography>
        <AnalysisWidget />
        <Box textAlign="right" mt={1}>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={() => navigate(`/app/${techId}/tech-detail/analysis`)}
          >
            {t('techDetail.analysis.moreInfo')}
          </Button>
        </Box>
      </Box>

      {/* Nastavení techniky */}
      <Box my={2}>
        <Typography variant="sectionHeadline">Nastavení techniky</Typography>
        <Paper>
          <Box p={2} textAlign="right">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(`/app/${techId}/tech-detail/settings`)}
              size="small"
            >
              Přejít na nastavení techinky
            </Button>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default TechDetail;
