import React from 'react';
import { 
  Box,
  Typography,
  Button,
} from '@mechis/elements';
import RecordList from '../RecordList';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useTech from '@hooks/useTech';

const RecordWidget = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { techId } = useTech();
  
  return (
    <Box my={2}>
      <Typography variant="sectionHeadline">
        {t('records.widget.title')}
      </Typography>
      <RecordList isWidget={true} />
      <Box textAlign="right" mt={1}>
        <Button
          onClick={() => {
            navigate(`/app/${techId}/tech-detail/records`);
          }}
          color="secondary"
          size="small"
          variant="contained"
        >
          {t('records.widget.showMore')}
        </Button>
      </Box>
    </Box>
  );
};

export default RecordWidget;
