import React, { FC } from 'react';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';
import { AttachmentsContent } from '@mechis/sections/Attachments';

const EditAvatar: FC = () => {
  useHeader({
    headline: 'appBar.headline.editAvatar',
    widget: (
      <Selections>
        <ControlMenu />
      </Selections>
    ),
  });
  
  return (
    <>
      <AttachmentsContent
        variant="avatar"
        isUpdateAvatar
      />
    </>
  );
};

export default EditAvatar;
