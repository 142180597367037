import React from 'react';
import {
  AttachmentsContent,
} from '@mechis/sections/Attachments';

const Avatar = () => {
  return (
    <div>
      <AttachmentsContent
        variant="avatar"
      />
    </div>
  );
};

export default Avatar;
