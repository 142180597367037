import React, { FC, useState, useEffect } from 'react';
import { useAttachments, IAttachmentsListItemProps } from '@mechis/sections/Attachments';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  CircularProgress,
  IconButton,
} from '@mechis/elements';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import DeleteIcon from '@mui/icons-material/Delete';

const AttachmentsListItem: FC<IAttachmentsListItemProps> = ({
  key,
  variant,
  src,
  filePreview,
  alt,
  type,
  name,
  size,
  onDelete,
  onClick,
}) => {
  const { isUploading, getType, getFileURL } = useAttachments();
  const [ source, setSource ] = useState<string | null>(null); 

  useEffect(() => {
    const fetchAvatarUrl = async () => {
      try {
        const url = await getFileURL(src);
        setSource(url);
      } catch (error) {
        console.log('Error fetching avatar URL:', error);
        setSource(null);
      }
    };

    if (filePreview) {
      setSource(filePreview);
    } else {
      fetchAvatarUrl();
    }
  }, [ src, filePreview ]);

  const onClickAvatar = () => {
    if (getType(type) === 'doc') {
      window.open(src, '_blank');
    }
    if (getType(type) === 'image' && onClick) {
      onClick();
    }
  };
  
  const getAvatar = () => {
    switch (type) {
      case 'application/pdf': return <PictureAsPdfIcon color="error" />;
      case 'application/msword': return <TextSnippetIcon color="info" />;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': return <TextSnippetIcon color="info" />;
      case 'text/plain': return <TextSnippetIcon color="disabled" />;
      case 'application/vnd.ms-excel': return <BorderAllIcon color="success" />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': return <BorderAllIcon color="success" />;
      case 'text/csv': return <BorderAllIcon color="success" />;
      default: return null;
    }
  };

  return (
    <ListItem 
      key={key}
      sx={{
        my: 2,
        borderRadius: '16px',
        border: variant === 'selected' ? '1px dashed lightgrey' : null,
        minHeight: variant === 'selected' ? '75px' : null,
      }}
    >
      <ListItemAvatar onClick={onClickAvatar}>
        <Avatar
          variant="rounded"
          src={source || undefined}
          alt={alt}
          sx={{
            border: '1px solid lightgrey',
            backgroundColor: '#bdbdbd1f',
          }} 
        >
          {getAvatar()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={
          variant === 'selected' && size ?
            `${(size / 1024 / 1024).toFixed(2)} MB` :
            null
        }
        primaryTypographyProps={{
          sx: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            width: '92%',
          },
        }}
      />
      <ListItemSecondaryAction>
        {isUploading ? (
          <CircularProgress />
        ) : (
          <>
            {onDelete && (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default AttachmentsListItem;
