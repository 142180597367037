import React, { FC, useEffect, useState } from 'react';
import {
  ATTACHEMENTS_ALLOWED_TYPES,
  IAttachmentsControlProps,
} from '@mechis/sections/Attachments';
import { ControlBlock } from './styles';
import useAttachments from './useAttachments';

const AttachmentsControl: FC<IAttachmentsControlProps> = ({
  children,
  variant = 'attachments',
  target = 'direct',
  allowedTypes = ATTACHEMENTS_ALLOWED_TYPES,
}) => {
  const [ isDirectInputTriggered, setIsDirectInputTriggered ] = useState(false);
  const { setIsModalOpen, handleFileChange, selectedFilesState } = useAttachments();

  useEffect(() => {
    if (selectedFilesState.length > 0 && isDirectInputTriggered) {
      setIsModalOpen(true);
      setIsDirectInputTriggered(false);
    }
  }, [ selectedFilesState ]);

  const handleDirectInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirectInputTriggered(true);
    handleFileChange(e, variant);
  };

  return (
    <ControlBlock
      onClick={target === 'modal' ? () => setIsModalOpen(true) : undefined}
    >
      {target === 'direct' && (
        <>
          <input
            accept={allowedTypes.join(',')}
            style={{ display: 'none' }}
            id="icon-button-file"
            type="file"
            multiple={variant === 'attachments' ? true : false}
            onChange={handleDirectInputChange}
          />
          <label 
            htmlFor="icon-button-file"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {children}
          </label>
        </>
      )}
      {target === 'modal' && children}
    </ControlBlock>
  ); 
};

export default AttachmentsControl;
