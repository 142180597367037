import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Stack,
  CircularProgress,
} from '@mechis/elements';
import Odometer from '@mechis/blocks/OdometerBlock';
import { useTranslation } from 'react-i18next';
import {
  ActualOdometerStateByDeviceIdDocument,
  DeviceOperationalDataDocument,
  useActualOdometerStateByDeviceIdQuery,
  useCreateEventMutation,
} from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';
import { EVENT_TYPE_ODOMETER } from '@configurations/constants/eventTypes';
import ErrorIcon from '@mui/icons-material/Error';
import Loader from '@mechis/blocks/Loader';
import { useNavigate } from 'react-router';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import { useAttachments } from '@mechis/sections/Attachments';
import selectionsCacheControl from '@state/mutations/selections';
import useTech from '@hooks/useTech';

const NewOdometer = () => {
  useHeader({
    headline: 'appBar.headline.odometer',
    widget: (
      <Selections
        date={{ isSelection: true, order: 1 }}
        attachments={{ isSelection: true, order: 2 }}
      />
    ),
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { techId } = useTech();
  const [ isUploading, setIsUploading ] = useState<boolean>(false);

  const [ odometerValue, setOdometerValue ] = useState<number>(0);
  const [ lastOdometerState, setLastOdometerState ] = useState<number>(0);
  const [ validationError, setValidationError ] = useState(false);
  const [ userPressedSubmit, setUserPressedSubmit ] = useState(false);
  const [ description, setDescription ] = useState('');
  const [ eventDate, setEventDate ] = useState<Date>(new Date);

  const { uploadFiles, setSelectedFiles } = useAttachments();
  const { resetMutationsInputSelections } = selectionsCacheControl;

  const { setSnackbar } = snackbarCacheControl;
  const { data: selectionData } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  
  const [ createEvent, {error: errorUpdatingOdometer, loading} ] = useCreateEventMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ActualOdometerStateByDeviceIdDocument,
        variables: {
          deviceId: techId,
        },
      },
      {
        query: DeviceOperationalDataDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });

  const {
    data: actualOdometerData,
    loading: actualOdometerDataLoading,
    error: actualOdometerDataError,
  } = useActualOdometerStateByDeviceIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      deviceId: techId,
    },
  });

  useEffect(() => {
    if ((odometerValue === 0 || Number.isNaN(odometerValue)) && userPressedSubmit) {
      setValidationError(true);
    } else {
      setValidationError(false);
    }
  }, [ odometerValue ]);

  useEffect(() => {
    if (!actualOdometerDataLoading && !actualOdometerDataError) {
      setLastOdometerState(actualOdometerData?.actualOdometerCalculated ?? 0);
    }
  }, [ actualOdometerData, actualOdometerDataLoading, actualOdometerDataError ]);

  useEffect(() => {
    if (selectionData?.selections?.dateValue) {
      setEventDate(selectionData?.selections?.dateValue);
    }
  }, [ selectionData ]);

  const onSaveOdometer = async () => {
    setIsUploading(true);
    setUserPressedSubmit(true);
    setValidationError(false);
    if (!techId) {
      return;
    }

    if (!odometerValue || odometerValue === 0) {
      setSnackbar('error', 'ODOMETER_MISSING');
      setValidationError(true);
      setIsUploading(false);
      return;
    }

    let attachmentIds = [];
    const uploadedFiles = await uploadFiles();
    if (uploadedFiles) {
      const ids = uploadedFiles.map((attachment) => attachment.id);
      attachmentIds = ids;
    }

    await createEvent({
      variables: {
        event: {
          device: {
            id: techId,
          },
          description: description,
          name: 'odometer',
          eventType: {
            id: EVENT_TYPE_ODOMETER,
          },
          eventDate: eventDate,
          stateOdometer: Number(odometerValue),
        },
        attachmentIds,
      },
    });
    if (errorUpdatingOdometer) {
      setSnackbar('error', 'ODOMETER_CREATE_ERROR');
      setIsUploading(false);
    } else {
      setSnackbar('success', 'ODOMETER_CREATE_SUCCESSFUL');
      setSelectedFiles([]);
      resetMutationsInputSelections();
      setIsUploading(false);
    }

    navigate(`/app/${techId}/tech-detail/records`);
  };

  if (actualOdometerDataLoading) {
    return (
      <Loader />
    );
  }

  if (actualOdometerDataError) {
    return (
      <div>
        <ErrorIcon />
      </div>
    );
  }

  return (
    <div>
      <Box mt={7}>
        <Odometer
          isBgDefault
          odometerValue={odometerValue}
          setOdometerValue={setOdometerValue}
          currentOdometerValue={lastOdometerState}
          validationError={validationError}
        />
      </Box>
      <Box mt={5}>
        <Stack
          direction="row"
          justifyContent="center"
        >
          <Button
            variant="outlined"
            onClick={() => setOdometerValue(lastOdometerState)}
          >
            Vyplnit poslední hodnotou
          </Button>
        </Stack>
      </Box>

      <Box mt={5}>
        <TextField
          multiline
          rows={4}
          variant="outlined"
          label={t('newOdometer.form.description.label')}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Box>
      <Box mt={4}>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          sx={{ width: '100%' }}
          disabled={loading || validationError || isUploading}
          onClick={onSaveOdometer}
        >
          {isUploading ? (<CircularProgress />) : t('newOdometer.form.save')}
        </Button>
      </Box>
    </div>
  );
};

export default NewOdometer;
