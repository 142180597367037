import React, { FC } from 'react';
import { Typography, Stack } from '@mechis/elements';
import { IOperationLabelProps } from '../../types';
import useIcon from '@mechis/icons/useIcon';
import { resolveOperationType } from '@mechis/sections/MaintenanceChecklist/components/Operation';

const OperationLabel: FC<IOperationLabelProps> = ({ name, type }) => {

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {useIcon(resolveOperationType(type), { sx: { marginRight: '4px', fontSize: '1.25em' } })}
      <Typography variant="body2">
        {name}
      </Typography>
    </Stack>
  );
};

export default OperationLabel;
