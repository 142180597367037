import React, { FC } from 'react';
import { IProps } from './types';
import {
  Section,
  WhiteText,
  BottomNav,
  BottomNavAction,
  ItemTextLink,
  MenuItem,
} from './styles';
import useNavigate from '@hooks/useNavigate';
import useAuth from '@hooks/useAuth';
import useIcon from '@mechis/icons/useIcon';
import appCacheControl from '@state/mutations/app';
import {
  Divider,
  Box,
  Stack,
  ListItemIcon,
  ListItemText,
  IconButton, Link,
} from '@mechis/elements';
// import Logo from '@mechis/blocks/Logo';
import Logo2 from '@mechis/blocks/Logo/Logo2';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { DEVICE_ID } from '@configurations/constants/app';
import {useTranslation} from 'react-i18next';
import {getPathToLegalFile, LegalFileType} from '@utilities/legalFilesPath';

const AppMenu: FC<IProps> = () => {
  const {
    onLogout,
    user,
    LogoutConfirmModal,
    isComgateUser,
  } = useAuth();
  const { navigate } = useNavigate();
  const { t } = useTranslation();
  const { setIsMenuOpened } = appCacheControl;
  const techId = localStorage.getItem(DEVICE_ID);

  const onNavigate = (link: string) => {
    setIsMenuOpened(false);
    navigate(link);
  };

  const showMaintenance = !isComgateUser();

  const handleBottomNavigationChange = (value: any) => {
    switch (value) {
      case 0:
        onNavigate('/app/mechis');
        break;
      case 1:
        onNavigate('/app/settings');
        break;
      case 2:
        // do nothing (custom logout function onClick)
        break;
      default: return null;
    }
  };

  return (
    <Section>
      <LogoutConfirmModal />
      <Box mt={2} mb={2}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Box>
            <IconButton
              color="primary"
              size="small"
              isSquared
              sx={{ mt: 0 }}
              onClick={() => onNavigate('/app/my-profile')}
            >
              <PersonIcon
                fontSize="medium"
              />
            </IconButton>
          </Box>
          <Box onClick={() => onNavigate('/app/my-profile')}>
            <WhiteText
              variant="h6"
              sx={{
                fontWeight: '500',
                mb: -1,
              }}
            >
              Vítej,
            </WhiteText>
            <WhiteText variant="h4">
              {user?.firstName || user?.login}
            </WhiteText>
          </Box>
        </Stack>
      </Box>
      <Divider sx={{ opacity: 0.4 }} />
      <Box mt={2} sx={{ height: 'calc(100vh - 225px)', overflow: 'scroll' }}>
        <MenuItem
          disableGutters
          onClick={() => onNavigate('/app')}
        >
          <ListItemIcon>
            {useIcon('mechisCar', {
              htmlColor: '#ffffff',
              sx: {
                opacity: 0.8,
              },
            })}
          </ListItemIcon>
          <ItemTextLink variant="white">
            Přehled techniky
          </ItemTextLink>
        </MenuItem>

        <Divider sx={{ opacity: 0.4 }} />

        <Stack sx={{ py: 1 }}>
          <MenuItem
            disableGutters
          >
            <ListItemText variant="white">
              Technika
            </ListItemText>
          </MenuItem>
          <Box sx={{ pl: 1.5 }}>
            <MenuItem
              disableGutters
              onClick={() => onNavigate(`/app/${techId}/tech-detail`)}
            >
              <ListItemIcon>
                {useIcon('dashboard', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Karta techniky
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate(`/app/${techId}/tech-detail/info`)}
            >
              <ListItemIcon>
                {useIcon('info', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Údaje
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate(`/app/${techId}/tech-detail/records`)}
            >
              <ListItemIcon>
                {useIcon('record', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Záznamy
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate(`/app/${techId}/tech-detail/attachments`)}
            >
              <ListItemIcon>
                {useIcon('attachment', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Přílohy
              </ItemTextLink>
            </MenuItem>
            {showMaintenance && (
              <MenuItem
                disableGutters
                onClick={() => onNavigate(`/app/${techId}/tech-detail/maintenance-plan`)}
              >
                <ListItemIcon>
                  {useIcon('maintenance', {
                    htmlColor: '#ffffff',
                    sx: {
                      opacity: 0.8,
                    },
                  })}
                </ListItemIcon>
                <ItemTextLink variant="white">
                  Plán údržby
                </ItemTextLink>
              </MenuItem>
            )}
            <MenuItem
              disableGutters
              onClick={() => onNavigate(`/app/${techId}/tech-detail/analysis`)}
            >
              <ListItemIcon>
                {useIcon('analysis', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Analýza
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate(`/app/${techId}/tech-detail/settings`)}
            >
              <ListItemIcon>
                {useIcon('techSettings', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Nastavení techniky
              </ItemTextLink>
            </MenuItem>
          </Box>
        </Stack>

        <Divider sx={{ opacity: 0.4 }} />

        <Stack sx={{ py: 1 }}>
          <MenuItem
            disableGutters
            onClick={() => onNavigate('')}
          >
            <ListItemText variant="white">
              Přidat
            </ListItemText>
          </MenuItem>
          <Box sx={{ pl: 1.5 }}>
            <MenuItem
              disableGutters
              onClick={() => onNavigate('/app/new-tech')}
            >
              <ListItemIcon>
                {useIcon('newTech', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Nová technika
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate(`/app/${techId}/new-odometer`)}
            >
              <ListItemIcon>
                {useIcon('newOdometer', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Tachometr
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate(`/app/${techId}/new-refueling`)}
            >
              <ListItemIcon>
                {useIcon('newRefueling', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Tankování
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate(`/app/${techId}/new-expenses`)}
            >
              <ListItemIcon>
                {useIcon('newExpenses', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Výdaje
              </ItemTextLink>
            </MenuItem>
            {showMaintenance && (
              <MenuItem
                disableGutters
                onClick={() => onNavigate(`/app/${techId}/maintenance/new-event/select-device`)}
              >
                <ListItemIcon>
                  {useIcon('newMaintenance', {
                    htmlColor: '#ffffff',
                    sx: {
                      opacity: 0.8,
                    },
                  })}
                </ListItemIcon>
                <ItemTextLink variant="white">
                  Údržba
                </ItemTextLink>
              </MenuItem>
            )}
            <MenuItem
              disableGutters
              onClick={() => onNavigate(`/app/${techId}/new-reminders`)}
            >
              <ListItemIcon>
                {useIcon('newReminders', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Upomínky
              </ItemTextLink>
            </MenuItem>
          </Box>
        </Stack>

        {/* <Divider sx={{ opacity: 0.4 }} />

        <Stack sx={{ py: 1 }}>
          <MenuItem
            disableGutters
            onClick={() => onNavigate('')}
          >
            <ListItemText variant="white">
              Nabídka
            </ListItemText>
          </MenuItem>
          <Box sx={{ pl: 1.5 }}>
            <MenuItem
              disableGutters
              onClick={() => onNavigate('')}
            >
              <ListItemIcon>
                {useIcon('analysis', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Odkaz 1
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate('')}
            >
              <ListItemIcon>
                {useIcon('analysis', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Odkaz 2
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate('')}
            >
              <ListItemIcon>
                {useIcon('analysis', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Odkaz 3
              </ItemTextLink>
            </MenuItem>
          </Box>
        </Stack> */}

        <Divider sx={{ opacity: 0.4 }} />

        <Stack sx={{ py: 1 }}>
          <MenuItem
            disableGutters
            onClick={() => onNavigate('')}
          >
            <ListItemText variant="white">
              Ostatní
            </ListItemText>
          </MenuItem>
          <Box sx={{ pl: 1.5 }}>
            <MenuItem
              disableGutters
              onClick={() => onNavigate('/app/mechis')}
            >
              <ListItemIcon>
                <Logo2 variant="xsmall" />
              </ListItemIcon>
              <ItemTextLink variant="white">
                Cloudový účet
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate('/app/my-profile')}
            >
              <ListItemIcon>
                {useIcon('person', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Můj profil
              </ItemTextLink>
            </MenuItem>
            <MenuItem
              disableGutters
              onClick={() => onNavigate('/app/settings')}
            >
              <ListItemIcon>
                {useIcon('settings', {
                  htmlColor: '#ffffff',
                  sx: {
                    opacity: 0.8,
                  },
                })}
              </ListItemIcon>
              <ItemTextLink variant="white">
                Nastavení aplikace
              </ItemTextLink>
            </MenuItem>
          </Box>
        </Stack>
        <Box mt={2}>
          <Link
            sx={{
              fontSize: '0.85rem',
              lineHeight: 1,
              opacity: .7,
              margin: 'auto',
              display: 'block',
              minWidth: '251px',
              paddingBottom: 1.5,
              textAlign: 'center',
              color: 'white !important',
              textDecoration: 'underline',
            }}
            href={getPathToLegalFile(LegalFileType.TermsAndConditions)}
            download
            variant="body2"
            component="a"
          >
            {t('login.links.generalTermsAndConditions')}
          </Link>
          <Link
            sx={{
              fontSize: '0.85rem',
              lineHeight: 1,
              opacity: .7,
              margin: 'auto',
              display: 'block',
              minWidth: '251px',
              paddingBottom: 1.5,
              textAlign: 'center',
              color: 'white !important',
              textDecoration: 'underline',
            }}
            href={getPathToLegalFile(LegalFileType.Cookies)}
            download
            variant="body2"
            component="a"
          >
            {t('login.links.cookies')}
          </Link>
          <Link
            sx={{
              fontSize: '0.85rem',
              lineHeight: 1,
              opacity: .7,
              margin: 'auto',
              display: 'block',
              minWidth: '251px',
              paddingBottom: 1.5,
              textAlign: 'center',
              color: 'white !important',
              textDecoration: 'underline',
            }}
            href={getPathToLegalFile(LegalFileType.GDPR)}
            download
            variant="body2"
            component="a"
          >
            {t('login.links.gdpr')}
          </Link>
        </Box>
      </Box>
      <BottomNav
        showLabels
        onChange={(event, newValue) => handleBottomNavigationChange(newValue)}
      >
        {/* <Logo variant="white" size={36} /> */}
        <BottomNavAction
          icon={
            <Logo2 variant="medium" />
          }
        />
        <BottomNavAction
          label="Nastavení"
          icon={
            <SettingsIcon
              fontSize="medium"
            />
          }
        />
        <BottomNavAction
          id="cy-mobile-logout"
          label="Odhlásit se"
          onClick={onLogout}
          icon={
            <LogoutIcon
              fontSize="medium"
            />
          }
        />
      </BottomNav>
    </Section>
  );
};

export default AppMenu;
