import differenceInDays from 'date-fns/differenceInDays';
import { OutputDateFormats } from '@configurations/types/global';
import { format } from 'date-fns';
import {InputMaybe, TransmissionsQuery, VehicleTypesQuery} from '@state/mechis-backend/generated/schema';

export const blurTarget = (event: any): void => event.target.blur();

export const ensureNumericZeroIsNotDisplayed = (value: InputMaybe<number> | undefined) => value === 0 ? null : value;

export const clearZero = (value: InputMaybe<number> | undefined) => value === 0 ? '' : value;

export const calculateDueDate = (endDate: string) => {
  return differenceInDays(new Date(endDate), new Date());
};
export const tableOutputString = (deviceDetail: string | undefined | null): string => {
  if (deviceDetail === '' || deviceDetail === undefined || deviceDetail === null) {
    return '-';
  }
  return deviceDetail;
};
export const tableOutputNumber = (deviceDetail: number | undefined | null): number | string => {
  if (deviceDetail === 0 || deviceDetail === undefined || deviceDetail === null) {
    return '-';
  }
  return deviceDetail;
};
export const tableOutputDate = (dateTime: Date, outputFormat: OutputDateFormats) => {
  return dateTime ? format(new Date(dateTime), outputFormat) : '-';
};

export const getVehicleTypeNameOrFallbackToId = (
  typeOfVehicle: VehicleTypesQuery | undefined,
  id: string,
  translationFn: (a: string) => string): string => {
  const vehicleCategory = typeOfVehicle?.vehicleTypes.find((v) => v.id === id);

  return vehicleCategory?.name ? translationFn(`vehicleTypes.${vehicleCategory?.name}`) : id;
};

export const getTransmissionNameOrFallbackToId = (
  transmissions: TransmissionsQuery | undefined,
  id: string,
  translationFn: (a: string) => string): string => {
  const transmission = transmissions?.transmissions.find((t) => t.id === id);

  return transmission?.name ? translationFn(`transmission.${transmission?.name}`) : id;
};

export const isObjectEmpty = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false; // Object has at least one property
    }
  }
  return true; // Object is empty
};

// todo: fix typescript
export const getLowestDueDate = (interval: any) => {
  if (interval && interval.operation.length > 0) {
    const validOperations = interval.operation.filter((op: any) => op.periodEndDate);
    if (validOperations.length === 0) return null;
    return validOperations.reduce((prev: any, current: any) => {
      if (!current.periodEndDate) return prev;
      if (!prev.periodEndDate) return current; 

      const prevDueDate = calculateDueDate(prev.periodEndDate);
      const currentDueDate = calculateDueDate(current.periodEndDate);
      return currentDueDate < prevDueDate ? current : prev;
    });
  } else {
    return null;
  }
};

export const formatSize = (sizeInMB: number): string => {
  if (sizeInMB >= 1024) {
    const sizeInGB = (sizeInMB / 1024).toFixed(1); // No decimal places for GB
    return `${sizeInGB} GB`;
  } else {
    return `${sizeInMB} MB`;
  }
};
