import React, { FC } from 'react';
import {
  IProps,
  ISimpleTableData,
  ISimpleTableCta,
} from './types';
import {
  Block,
  CellLabel,
  CellValue,
} from './styles';
import { v4 as uuid } from 'uuid';
import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableRow,
  Button,
  Stack,
} from '@mechis/elements';

const SimpleTable: FC<IProps> = ({ title, data, ctas, emptyState }) => {
  return (
    <Block>
      {typeof title === 'string' ? <Typography variant="sectionHeadline">{title}</Typography> : title}
      <Paper>
        <Box>
          <Table>
            <TableBody>
              {data.map((row: ISimpleTableData) =>
                <TableRow key={uuid()}>
                  <CellLabel>{row.label}</CellLabel>
                  <CellValue>{row.value}</CellValue>
                </TableRow>
              )}
              {!data || data.length === 0 && emptyState}
            </TableBody>
          </Table>
          {ctas && (
            <Stack
              direction="row-reverse"
              spacing={1.5}
            >
              {ctas.map((cta: ISimpleTableCta) => 
                <Button
                  key={uuid()}
                  color={cta.color}
                  size="small"
                  variant="contained"
                  sx={{ position: 'relative', top: '10px' }}
                  onClick={cta.onClick}
                >
                  {cta.title}
                </Button>
              )}
            </Stack>
          )}
        </Box>
      </Paper>
    </Block>
  );
};

export default SimpleTable;
