import React, { FC, useEffect, useState } from 'react';
import { IProps } from './types';
import { Block } from './styles';
import {
  Box,
  Paper,
  Stack,
  Chip,
} from '@mechis/elements';
import SpeedIcon from '@mui/icons-material/Speed';
import NumberFormat from 'react-number-format';
import { THOUSAND_SEPARATOR } from '@configurations/constants/app';
import useTech, { UnitType } from '@hooks/useTech';
import NumberField from '@mechis/elements/NumberField';
import { ensureNumericZeroIsNotDisplayed } from '@utilities/app';

const Odometer: FC<IProps> = ({
  currentOdometerValue,
  odometerValue,
  setOdometerValue,
  odometerState,
  validationError,
  isBgDefault = false,
}) => {
  const [ hasError, setHasError ] = useState(false);
  const { getTechUnit } = useTech();

  useEffect(() => {
    if (odometerState === 'missing') {
      setHasError(true);
    }
  }, [ odometerState ]);

  useEffect(() => {
    setHasError(validationError || false);
  }, [ validationError ]);

  const validateOdometerField = (value: string) => {
    if (value === '') {
      setHasError(true);
    }
  };

  return (
    <Block>
      <Paper variant="outlined">
        <Stack direction="row" justifyContent="space-between">
          <SpeedIcon
            fontSize="large"
            color="primary"
            sx={{
              fontSize: (theme) => `${theme.spacing(9)}`,
              // position: 'relative',
              mt: -5,
              ml: 2,
              mr: 1,
              pr: 1,
              pl: 1,
              background: (theme) => isBgDefault ? theme.palette.background.default : theme.palette.common.white,
            }}
          />
          <Chip
            label={
              <NumberFormat 
                value={currentOdometerValue}
                displayType="text"
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalScale={2}
                suffix={getTechUnit(UnitType.Odometer, true)}
              />
            }
            sx={{
              fontSize: '1rem',
              mt: -2,
              mr: 1,
              backgroundColor: '#e7e7e7',
              fontStyle: 'italic',
            }}
          />
        </Stack>
        <Box p={2} mt={-2} component="form" textAlign="center">
          <NumberField
            value={ensureNumericZeroIsNotDisplayed(odometerValue)}
            id="outlined-basic"
            variant="outlined"
            error={validationError !== undefined ? (hasError && validationError) : hasError}
            onBlur={(e) => validateOdometerField(e.target.value)}
            onFocus={() => setHasError(false)}
            onChange={(e) => setOdometerValue(Number(e.currentTarget.value))}
            inputProps={{
              min: 0,
              style: {
                textAlign: 'center',
                fontSize: '2rem',
              }}
            }
            onlyInteger
          />
        </Box>
      </Paper>
    </Block>
  );
};

export default Odometer;
