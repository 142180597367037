import { styled } from '@mui/material/styles';
import { shouldForwardProp as sysShouldForwardProp } from '@mui/system';
import { IOwnerState } from './types';
import { Typography } from '@mechis/elements';
import Vrp from '@mechis/blocks/Vrp';

export const Section = styled('section', {
  name: 'Selections',
  slot: 'Root',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Header = styled('div', {
  name: 'Selections',
  slot: 'Header',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

export const Content = styled('div', {
  name: 'Selections',
  slot: 'Content',
})(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const Tech = styled('div', {
  name: 'Selections',
  slot: 'Tech',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  position: 'relative',
  width: 'fit-content',
  '& > button': {
    position: 'absolute',
    bottom: '-10px',
    right: '-5px',
    transform: 'scale(0.85)',
  },
}));

export const TechHeadline = styled(Typography, {
  name: 'Selections',
  slot: 'Headline',
})(() => ({
  opacity: 0.8,
  display: 'block',
  width: '100%',
  fontFamily: 'Poppins',
  letterSpacing: '0.75px',
  fontSize: '1.075rem',
  marginBottom: 0,
}));

export const TechVrp = styled(Vrp, {
  name: 'Selections',
  slot: 'Vrp',
})(() => ({
  transform: 'scale(0.7)',
  position: 'relative',
  left: '-14px',
}));

export const Controls = styled('div', {
  name: 'Selections',
  slot: 'Controls',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Control = styled('div', {
  name: 'Selections',
  slot: 'Control',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginLeft: theme.spacing(2),
  '& > button > svg': {
    fontSize: '32px',
  },
  button: {
    borderRadius: theme.spacing(1),
  },
}));

export const ControlLabel = styled('div', {
  name: 'Selections',
  slot: 'ControlLabel',
  shouldForwardProp: (prop) => sysShouldForwardProp(prop) && prop !== 'ownerState',
})<{ ownerState: IOwnerState, theme?: any }>(({ ownerState, theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(0.75)}`,
  fontSize: '0.5875rem',
  color: 'white',
  ...(ownerState.state === 'filled' && {
    background: theme.palette.secondary.main,
  }),
  ...(ownerState.state === 'missing' && {
    background: theme.palette.error.main,
  }),
  ...(ownerState.state === 'default' && {
    background: theme.palette.background.main,
  }),
  borderRadius: theme.spacing(4),
  marginTop: theme.spacing(-0.75),
  zIndex: '1',
  opacity: 0.8,
  input: {
    padding: 0,
    textAlign: 'center',
    fontSize: '0.5875rem',
    color: 'white',
  },
  fieldset: {
    border: 0,
  },
  '.MuiTextField-root': {
    maxWidth: '38px',
  },
}));

