import React, { FC } from 'react';
import { IExpensesProps } from '../types';
import { useExpensesByEventQuery } from '@state/mechis-backend/generated/schema';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mechis/elements';
import Loader from '@mechis/blocks/Loader';
import SimpleTable from '@mechis/blocks/SimpleTable';
import ErrorIcon from '@mui/icons-material/Error';
import { CZK_UNIT_NAME, MAX_EXPENSES_FOR_LIST_IN_DETAIL_OF_EVENT, THOUSAND_SEPARATOR } from '@configurations/constants/app';
import NumberFormat from 'react-number-format';

const Preview: FC<IExpensesProps> = ({ id, onUpdate }) => {
  const { t } = useTranslation();

  const { data: expensesInfo, loading: expensesInfoLoading, error: expensesInfoError } = useExpensesByEventQuery({
    variables: {
      eventId: Number(id),
      offset: 0,
      limit: MAX_EXPENSES_FOR_LIST_IN_DETAIL_OF_EVENT,
    },
  });

  if (expensesInfoLoading) {
    return (
      <Loader />
    );
  }

  if (expensesInfoError) {
    return (
      <div>
        <ErrorIcon />
      </div>
    );
  }

  if (expensesInfo?.expensesByEvent?.expense.length === 0) {
    return <></>;
  }

  return (
    <Box mb={2}>
      <SimpleTable
        title={t('maintenanceNewEvent.preview.label')}
        data={expensesInfo?.expensesByEvent?.expense.map((expense) => ({
          label: expense.tag.tagName,
          value: expense.price + ' Kč',
        })) ?? []}
        ctas={[
          {
            title: 'Upravit',
            color: 'secondary',
            onClick: () => onUpdate('expenses'),
          },
        ]}
      />
      <Stack alignItems={'flex-end'} pt={1.5} pr={1.5}>
        <Typography variant="sectionHeadline" marginBottom={'-12px'}>{t('maintenanceNewEvent.preview.priceTotal')}</Typography>
        <Typography variant="h4">
          <NumberFormat
            suffix={` ${CZK_UNIT_NAME}`}
            value={expensesInfo?.expensesByEvent?.expense[0].event?.priceTotal ?? '0'}
            displayType="text"
            thousandSeparator={THOUSAND_SEPARATOR}
          />
        </Typography>
      </Stack>
    </Box>
  );
};

export default Preview;
