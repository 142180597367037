import React, { FC, useEffect, useState } from 'react';
import {
  useAttachments,
  IAttachmentsGridItemProps,
  GridItemHeadline,
} from '@mechis/sections/Attachments';
import { Grid } from '@mechis/elements';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import BorderAllIcon from '@mui/icons-material/BorderAll';

// todo: finish or delete commeted props here
const AttachmentsGridItem: FC<IAttachmentsGridItemProps> = ({
  key,
  variant,
  src,
  filePreview,
  alt,
  type,
  name,
  // size,
  // onDelete,
  onClick,
}) => {
  const { getType, getFileURL } = useAttachments();
  const [ source, setSource ] = useState<string | null>(null);

  useEffect(() => {
    const fetchAvatarUrl = async () => {
      try {
        const url = await getFileURL(src);
        setSource(url);
      } catch (error) {
        console.log('Error fetching avatar URL:', error);
        setSource(null);
      }
    };

    if (filePreview) {
      setSource(filePreview);
    } else {
      fetchAvatarUrl();
    }
  }, [ src, filePreview ]);

  const onClickGrid = () => {
    if (getType(type) === 'doc') {
      window.open(src, '_blank');
    }
    if (getType(type) === 'image' && onClick) {
      onClick();
    }
  };

  const getGrid = () => {
    if (type.startsWith('image/')) {
      return (
        <>
          <img
            src={source || ''}
            alt={alt}
            style={{ width: '100%', borderRadius: '8px', marginBottom: 0 }} 
          />
        </>
      );
    }

    switch (type) {
      case 'application/pdf':
        return (
          <>
            <PictureAsPdfIcon color="error" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'application/msword':
        return (
          <>
            <TextSnippetIcon color="info" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 
        return (
          <>
            <TextSnippetIcon color="info" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'text/plain':
        return (
          <>
            <TextSnippetIcon color="disabled" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'application/vnd.ms-excel':
        return (
          <>
            <BorderAllIcon color="success" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return (
          <>
            <BorderAllIcon color="success" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'text/csv':
        return (
          <>
            <BorderAllIcon color="success" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      default: return null;
    }
  };

  return (
    <Grid
      item
      xs={4}
      key={key}
      style={{
        minHeight: '110px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        
      }}
      onClick={onClickGrid}
    >
      <div
        style={{
          textAlign: 'center',
          ...(variant === 'selected' && {
            border: '1px dashed lightgrey',
            padding: '8px',
            borderRadius: '8px',
          }),
        }}
      >
        {getGrid()}
      </div>
    </Grid>
  );
};

export default AttachmentsGridItem;
