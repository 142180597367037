import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  LinearProgress,
  Paper,
  Typography,
  Stack,
  NativeSelect,
} from '@mechis/elements';
import normaliseToPercentage from '@utilities/normaliseToPercentage';
import { UserDataQuoteDocument, useUpgradeDataQuoteMutation, useUserDataQuoteQuery } from '@state/mechis-backend/generated/schema';
import { useLocalStorage } from 'react-use';
import { PRICE_CLOUD, USER_ID, BASE_DATA_QUOTE_UNIT } from '@configurations/constants/app';
import Loader from '@mechis/blocks/Loader';
import snackbarCacheControl from '@state/mutations/snackbar';
import { IProps } from './types';
import { formatSize } from '@utilities/app';

const StorageCapacityWidget: FC<IProps> = ({ setNoCreditOpen }) => {
  const { t } = useTranslation();
  const { setSnackbar } = snackbarCacheControl;
  const [ userId ] = useLocalStorage(USER_ID, '');
  const [ isDialogOpen, toggleDialogOpen ] = useState<boolean>(false);
  const { data, loading } = useUserDataQuoteQuery({
    variables: {
      userId: Number(userId),
    },
  });
  const [ upgradeDataQuoteMutation ] = useUpgradeDataQuoteMutation({
    onCompleted: () => setSnackbar('success', 'DATA_QUOTE_UPDATE_SUCCESSFUL'),
    onError: () => setSnackbar('error','DATA_QUOTE_UPDATE_ERROR'),
    refetchQueries: [
      {
        query: UserDataQuoteDocument,
        variables: {
          userId: Number(userId),
        },
      },
    ],
  });
  const [ selectedDataQuoteToUpgrade, setSelectedDataQuoteToUpgrade ] = useState<number>(1);
  const upgradeOptions = [
    { count: 1, MB: 256 },
    { count: 2, MB: 512 },
    { count: 4, MB: 1024 },
    { count: 8, MB: 2048 },
  ];

  const dataVolume = data?.user?.dataVolume ?? 0;
  const dataQuote = data?.user?.dataQuote ?? 0;
  const creditBalance = data?.user?.creditBalance ?? 0;
  const dataFree = data?.user?.membership.dataFree ?? 0;

  const getDataQuotePaidByPriceCloudForNewDataPreview = () => {
    return (dataQuote + (BASE_DATA_QUOTE_UNIT * selectedDataQuoteToUpgrade));
  };

  const getDataQuotePaidByPriceCloud = () => {
    return ((dataQuote - dataFree) + (BASE_DATA_QUOTE_UNIT * selectedDataQuoteToUpgrade));
  };

  const handleDialogOpen = () => {
    toggleDialogOpen(() => true);
  };
  const handleDialogClose = () => {
    toggleDialogOpen(() => false);
  };

  const handleEnlargeStorage = async () => {
    if (creditBalance < (PRICE_CLOUD * selectedDataQuoteToUpgrade)) {
      setNoCreditOpen(true);
      handleDialogClose();
      return;
    }
  
    await upgradeDataQuoteMutation({
      variables: {
        countOfDataQuoteToUnlock: selectedDataQuoteToUpgrade,
      },
    });
    handleDialogClose();
  };

  if (loading) {
    return <Loader size={80} />;
  }

  const getValueForProgressBar = (value: number, max: number) =>
    value < max ? normaliseToPercentage(value, max) : 100;

  const getDataQuotesOptions = () => {
    return upgradeOptions.map((upgradeOption) => {
      return <option color="black" key={upgradeOption.MB} value={upgradeOption.count}>{upgradeOption.MB}</option>;
    });
  };

  return (
    <Box>
      <Typography variant="sectionHeadline">{t('cloud.storage.title')}</Typography>
      <Paper>
        <Box p={3} textAlign="center">
          <Stack spacing={2} alignItems="center">
            <Typography sx={{ lineHeight: 1 }}>
              <small style={{ fontSize: '0.75em' }}>
                {t('cloud.storage.used', { dataVolume: formatSize(dataVolume), dataQuote: formatSize(dataQuote) })}
              </small>
            </Typography>
            <LinearProgress
              variant="determinate"
              color={dataVolume < dataQuote ? 'secondary': 'error'}
              value={getValueForProgressBar(dataVolume, dataQuote)}
              sx={{
                width: '100%',
                height: '10px',
                borderRadius: '8px',
              }}
            />
            <Button variant="contained" onClick={handleDialogOpen} color="secondary">
              {t('cloud.storage.add')}
            </Button>
          </Stack>
        </Box>
      </Paper>
      <Dialog open={isDialogOpen}>
        <Box
          padding={1}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography variant="h1">
            {t('cloud.storage.dialog.enlargeStorage')}
          </Typography>
          <Box padding={2}>
            <Typography>
              {t('cloud.storage.dialog.priceIs', {
                storageValue: BASE_DATA_QUOTE_UNIT + 'MB',
                mechisCreditValue: 10,
              })}
            </Typography>
          </Box>
          <Box>
            <Typography variant="sectionHeadline">{t('cloud.storage.dialog.addDataQuote')}</Typography>
            <NativeSelect
              id="dataQuote"
              fullWidth
              defaultValue={selectedDataQuoteToUpgrade}
              onChange={(e) => {
                setSelectedDataQuoteToUpgrade(+e.currentTarget.value);
              }}
            >
              {getDataQuotesOptions()}
            </NativeSelect>
          </Box>
          <Box>
            <Typography variant="sectionHeadline">
              {t('cloud.storage.dialog.newTotalDataQuote',
                {
                  newDataQuote: formatSize(getDataQuotePaidByPriceCloudForNewDataPreview()),
                }
              )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="sectionHeadline">
              {t('cloud.storage.dialog.newTotalDataQuotePrice',
                {
                  newDataQuotePrice: ((getDataQuotePaidByPriceCloud() / BASE_DATA_QUOTE_UNIT) * PRICE_CLOUD),
                }
              )}
            </Typography>
          </Box>
          <Box>
            <Button variant="text" onClick={handleDialogClose}>
              {t('cloud.storage.dialog.close')}
            </Button>
            <Button variant="contained" onClick={handleEnlargeStorage}>
              {t('cloud.storage.dialog.enlargeStorage')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default StorageCapacityWidget;
