import React from 'react';
import { useAttachments } from '@mechis/sections/Attachments';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
} from '@mechis/elements';

const AttachmentsConfirmDeleteModal = () => {
  const { isDeleteIntent, setIsDeleteIntent, onDeleteEventAttachment } = useAttachments();

  return (
    <Dialog open={isDeleteIntent ? true : false} sx={{ p: 2 }}>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Typography variant="sectionHeadline">Opravdu si přejete smazat soubor?</Typography>
        <Typography><small>{isDeleteIntent?.originalName}</small></Typography>
      </DialogContent>
      <DialogActions sx={{ px: 4 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => setIsDeleteIntent(null)}
          >
            Ne
          </Button>
          <Button
            variant="contained"
            color="error"
            size="medium"
            onClick={() => {
              if (isDeleteIntent) onDeleteEventAttachment(isDeleteIntent);
            }}
          >
            Ano
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AttachmentsConfirmDeleteModal;
