import React from 'react';
import { useAttachments, AttachmentsControl } from '@mechis/sections/Attachments';
import {
  Stack,
  Box,
  IconButton,
  Button,
} from '@mechis/elements';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

const AttachmentsActionsSelectedFiles = () => {
  const { setSelectedFiles, setIsModalOpen } = useAttachments();

  return (
    <Stack
      direction="row"
      alignItems="end"
      justifyContent="space-between"
      sx={{ width: '100%', p:2 }}
      spacing={8}
    >
      <Box sx={{ flexGrow: 1 }}>
        <AttachmentsControl>
          <IconButton
            size="large"
            sx={{
              border: '1px dashed lightgrey',
              marginTop: '16px',
              pointerEvents: 'none',
            }}
          >
            <DriveFolderUploadIcon />
          </IconButton>
        </AttachmentsControl>
      </Box>
      {/*note: please dont delete this block below, its for future purposes */}
      {/*
      <Box sx={{ flexGrow: 1 }} textAlign="center">
        <Typography variant="body2" sx={{ opacity: 0.8 }}><small><em>Celkem</em></small></Typography>
        <Typography variant="h4" mt={-0.5}>
          <CountUp
            start={0}
            decimals={2}
            end={selectedFilesTotalSize}
            duration={0.5}
            suffix=" MB"
          />
        </Typography>
        <LinearProgress
          color="secondary"
          variant="determinate"
          value={40}
          valueBuffer={80}
          sx={{
            height: 5,
            borderRadius: 5,
          }}
        />
      </Box>
      */}
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setSelectedFiles([])}
        >
          Reset
        </Button>
        <Button
          color="secondary"
          variant="contained"
          // onClick={() => uploadFiles()}
          onClick={() => setIsModalOpen(false)}
        >
          Pokračovat
        </Button>
      </Stack>
    </Stack>
  );
};

export default AttachmentsActionsSelectedFiles;
