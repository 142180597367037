import React, { FC, useEffect, useState } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '../Slide';
import Box from '../Box';
import useDebouncedValue from '@hooks/useDebouncedValue';

// todo: make typescript definitions here
const HideOnScroll: FC<any> = ({ children, window, onTrigger }) => {
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  const [ display, setDisplay ] = useState<'block' | 'none'>(trigger ? 'none' : 'block');
  const debouncedIn = useDebouncedValue(trigger, 100);

  useEffect(() => {
    onTrigger(trigger);
    if (!trigger) setDisplay('block');
  }, [ trigger ]);

  const handleOnExited = () => {
    if (trigger) setDisplay('none');
  };

  return (
    <Slide
      appear={false}
      direction="down"
      in={!debouncedIn}
      onExited={handleOnExited}
    >
      <Box sx={{ display }}>{children}</Box>
    </Slide>
  );
};

export default HideOnScroll;
