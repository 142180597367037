import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';
import { GET_NEW_REFUELING } from '@state/queries/newRefueling';
import snackbarCacheControl from '@state/mutations/snackbar';
import newRefuelingCacheControl from '@state/mutations/newRefueling';
import { 
  EventsDocument,
  FuelDevice,
  useCreateRefuelingMutation,
  useDeviceUsedFuelsQuery,
  useUpdateRefuelingMutation,
} from '@state/mechis-backend/generated/schema';
import { IFuelState } from '@state/models/newRefueling/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import selectionsCacheControl from '@state/mutations/selections';
import { useAttachments } from '@mechis/sections/Attachments';
import useTech from './useTech';

const useRefueling = () => {
  const { techId } = useTech();
  const { setSnackbar } = snackbarCacheControl;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ isUploading, setIsUploading ] = useState<boolean>(false);
  
  const { data: selectionsData } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const odometer = selectionsData?.selections.odometerValue || 0;
  const date = selectionsData?.selections?.dateValue;
  const odometerState = selectionsData?.selections.odometerState;

  const { data: cachedData } = useQuery(GET_NEW_REFUELING);
  const fuelTypeId = cachedData.newRefueling.fuelTypeId;
  const state = cachedData.newRefueling.state;
  const { setFuelTypeId, setState, getState, updateStateByFuel, setDescription } = newRefuelingCacheControl;
  const { setOdometerState, resetMutationsInputSelections } = selectionsCacheControl;
  const [ fuels, setFuels ] = useState<FuelDevice[]>([]);
  const { uploadFiles, setSelectedFiles } = useAttachments();

  const { data, loading, error } = useDeviceUsedFuelsQuery({
    variables: {
      deviceId: techId,
    },
  });

  const [ updateRefueling ] = useUpdateRefuelingMutation({
    refetchQueries: [ { query: EventsDocument } ],
    onCompleted: () => {
      setSnackbar('success', 'REFUELING_ADDED');
    },
  });

  const [ createRefueling ] = useCreateRefuelingMutation({
    refetchQueries: [ { query: EventsDocument } ],
    onCompleted: () => {
      setSnackbar('success', 'REFUELING_ADDED');
      resetMutationsInputSelections();
    },
  });

  useEffect(() => {
    if (data && !loading && !error) {
      if (data.device?.fuel) {
        const fuelsState: IFuelState[] = data.device?.fuel.map((fuel) => {
          return {
            fuelTypeId: `${fuel.fuelType?.id}`,
            fullTank: true,
            skipTank: false,
            fuelVolume: 0,
            pricePerUnit: 0,
            priceTotal: 0,
          };
        }); 
        setState(fuelsState);
      }
    }

    if (data && data.device?.fuel?.length && data.device.fuel[0].fuelType?.id) {
      setFuels(data.device.fuel);
      setFuelTypeId(data.device.fuel[0].fuelType?.id);
    }
  }, [ data, loading, error ]);

  const schema = yup.object({
    fuelVolume: yup.number()
      .moreThan(0, t('newRefueling.form.fuelVolume.required'))
      .required(t('newRefueling.form.fuelVolume.required')),
    pricePerUnit: yup.number()
      .moreThan(0, t('newRefueling.form.pricePerUnit.required'))
      .required(t('newRefueling.form.fuelVolume.required')),
    priceTotal: yup.number()
      .moreThan(0, t('newRefueling.form.priceTotal.required'))
      .required(t('newRefueling.form.fuelVolume.required')),
    fullTank: yup.boolean(),
    skipTank: yup.boolean(),
  }).required();

  const defaultValues: Partial<IFuelState> = {
    fuelVolume: 0,
    pricePerUnit: 0,
    priceTotal: 0,
    fullTank: true,
    skipTank: false,
  };

  const { control, handleSubmit, watch, setValue, clearErrors } = useForm<IFuelState>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const resolveFuelName = (id: string) => {
    return fuels.find((fuel) => fuel.fuelType?.id === id)?.fuelType?.fuelName || '';
  };
  
  const saveCurrentState = () => {
    const currentState = {
      fuelTypeId: fuelTypeId,
      ...control._formValues,
    };
    updateStateByFuel(fuelTypeId, currentState as IFuelState);
  };

  const isValid = (): boolean => {
    saveCurrentState();

    try {
      const currentState = getState();
      const editedEntries = currentState.filter((
        (fuel: IFuelState) => {
          return (
            fuel?.pricePerUnit !== defaultValues.pricePerUnit
            || fuel?.fuelVolume !== defaultValues.fuelVolume
            || fuel?.priceTotal !== defaultValues.priceTotal 
            || fuel?.fullTank !== defaultValues.fullTank 
            || fuel?.skipTank !== defaultValues.skipTank
          );
        }));

      editedEntries.forEach((fuel: IFuelState) => {
        schema.validateSync(fuel);
      });

      if (odometer < 1) {
        setOdometerState('missing');
        setSnackbar('error', 'ODOMETER_MISSING');
        return false;
      }

      return true;
    } catch {
      setSnackbar('error', 'REFUELING_ITEMS_INCOMPLETE_ERROR');
      return false;
    }
  };

  const onFuelChange = (id: string) => {
    saveCurrentState();
    const newState = _.filter(state, { fuelTypeId: id })[0];
    setValue('fuelVolume', newState.fuelVolume);
    setValue('pricePerUnit', newState.pricePerUnit);
    setValue('priceTotal', newState.priceTotal);
    setValue('fullTank', newState.fullTank);
    setValue('skipTank', newState.skipTank);
    setFuelTypeId(id);
    clearErrors();
  };

  const onCreateRefueling = async () => {
    setIsUploading(true);

    let finalState = [ ...state ];
    let activeFuel = _.filter(finalState, { fuelTypeId })[0];
    if (activeFuel) {
      finalState = finalState.filter((obj) => obj !== activeFuel);
      activeFuel = {
        fuelTypeId: fuelTypeId,
        ...control._formValues,
      };
      finalState = [ activeFuel, ...finalState ];
    }

    let attachmentIds = [];
    const uploadedFiles = await uploadFiles();
    if (uploadedFiles) {
      const ids = uploadedFiles.map((attachment) => attachment.id);
      attachmentIds = ids;
    }

    for (const fuel of finalState) {
      if (fuel.fuelVolume || fuel.pricePerUnit || fuel.priceTotal) {
        await createRefueling({
          variables: {
            event: {
              name: `Tankování: ${t(`fuelTypes.${resolveFuelName(fuel.fuelTypeId)}`)}`,
              priceTotal: +fuel.priceTotal,
              stateOdometer: odometer || 0,
              device: {
                id: techId,
              },
              eventDate: date,
              completionDay: date,
              creationDate: date,
              description: state.description,
            },
            refueling: {
              pricePerUnit: +fuel.pricePerUnit,
              fuelType: {
                id: parseInt(fuelTypeId),
              },
              unitNumber: +fuel.fuelVolume,
              fullTank: fuel.fullTank ?? false,
              skipTank: fuel.skipTank ?? false,
            },
            attachmentIds,
          },
        });
      } 
    }
    
    setSelectedFiles([]);
    setIsUploading(false);
    navigate(`/app/${techId}/tech-detail/records`);
  };

  const onEditRefueling = async (fuelState: IFuelState) => {
    await updateRefueling({
      variables: {
        refueling: {
          id: fuelState.fuelId,
          pricePerUnit: fuelState?.pricePerUnit,
          fuelType: {
            id: parseInt(fuelState.fuelTypeId),
          },
          unitNumber: fuelState?.fuelVolume ?? 0,
          fullTank: fuelState?.fullTank ?? false,
          skipTank: fuelState?.skipTank ?? false,
          event: {
            priceTotal: fuelState?.priceTotal,
          },
        },
      },
    });
  };

  return {
    fuelTypeId,
    onFuelChange,
    fuels,
    loading,
    error,
    createRefueling,
    control,
    handleSubmit,
    isValid,
    watch,
    setValue,
    setDescription,
    description: state.description,
    onCreateRefueling,
    onEditRefueling,
    isUploading,
    odometerState,
  };
};

export default useRefueling;
