import React, { FC, useEffect } from 'react';
import useHeader from '@hooks/useHeader';
import { Box } from '@mechis/elements';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';
import { AttachmentsContent } from '@mechis/sections/Attachments';
import selectionsControl from '@state/mutations/selections';
import { useAttachments } from '@mechis/sections/Attachments';

const Attachments: FC = () => {
  useHeader({
    headline: 'appBar.headline.attachments',
    widget: (
      <Selections
        tagFilters={{ isSelection: true, order: 1 }}
        generalFilters={{
          isSelection: true,
          order: 2,
          isDate: true,
          isOdometer: true,
          isPrice: true,
          isRecordType: true,
        }}
      >
        <ControlMenu />
      </Selections>
    ),
  });

  const {
    setTags,
  } = selectionsControl;

  const { setAttachments } = useAttachments();

  useEffect(() => {
    setTags([]);

    return () => {
      setAttachments([]);
    };
  }, []);

  return (
    <Box mt={-2}>
      <AttachmentsContent variant="attachments" />
    </Box>
  );
};

export default Attachments;
