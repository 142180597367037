export const API_URL = 'https://mechisapp.herokuapp.com';
export const GRAPHQL_ENDPOINT = `${API_URL}/graphql`;
export const REFRESH_TOKEN_URL = `${API_URL}/refresh_token`;

export const FLASH_MESSAGE_AUTO_DISMISS_TIME = 5000;

export const ACCESS_TOKEN = 'access-token';
export const IS_DESKTOP_SUPPORTED = 'is-desktop-supported';
export const USER_ID = 'uid';
export const COOKIES_BAR_SUBMITTED = 'cookies-bar-submitted';
export const DEVICE_ID = 'device-id';

//FIREBASE CONFIGURATION
export const FIREBASE_AUTH_DOMAIN = 'mechis-15ee9.firebaseapp.com';
export const FIREBASE_PROJECT_ID = 'mechis-15ee9';
export const FIREBASE_STORAGE_BUCKET = 'mechis-15ee9.appspot.com';
export const FIREBASE_MESSAGING_SENDER_ID = '176662917907';
export const FIREBASE_APP_ID = '1:176662917907:web:b47cb4eb672517496820e6';

export const NON_BREAKING_SPACE = '\u00a0';
export const THOUSAND_SEPARATOR = '\u00a0';
export const CURRENCY_DEFAULT_ID = 1;
export const OTHERS_TAG_ID = 158;
export const CZK_UNIT_NAME = 'Kč';
export const LITRE_UNIT_NAME = 'l';

export const MAX_EXPENSES_FOR_LIST_IN_DETAIL_OF_EVENT = 25;

//ATTACHMENTS
export const ATTACHMENT_LIMIT_WIDGET = 6;
export const ATTACHMENT_LIMIT_LIST = 24;

export const PRICE_CLOUD = 10;
export const BASE_DATA_QUOTE_UNIT = 256;

export const TAGS_BY_USAGE_LIMIT = 10;
