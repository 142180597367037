import React, { FC, useEffect, useState } from 'react';
import { IProps } from './types';
import { Block } from './styles';
import { Box } from '@mechis/elements';
import { ImageContainer } from '@mechis/elements/Image/styles';
import useIcon from '@mechis/icons/useIcon';
import useIconNameResolverByDeviceCategoryId from '@mechis/icons/useIconNameResolverByDeviceCategoryId';
import { alpha } from '@mui/material/styles';
import palette from '@configurations/themes/alt/palette';
import useAttachments from '@mechis/sections/Attachments/useAttachments';

const DeviceAvatar: FC<IProps> = ({ 
  imageId,
  deviceCategoryId,
  size = 60,
  isEven = true,
}) => {
  const { getFileURL } = useAttachments();
  const [ imageUrl, setImageUrl ] = useState<string | null>(null);

  const variant = () => {
    if (size === 42) return 'small';
    if (size === 60) return 'medium';
    if (size === 75) return 'big';
  };

  const ownerState = {
    variant: variant(),
  };

  useEffect(() => {
    if (imageId) {
      getFileURL(imageId).then(setImageUrl).catch(console.error);
    }
  }, [ imageId, getFileURL ]);

  return (
    <Block ownerState={ownerState}>
      <Box
        sx={{
          background: (theme) => isEven ? alpha(theme.palette.primary.main, 0.15) : alpha(theme.palette.secondary.main, 0.15),
          borderRadius: '12px',
        }}
      >
        <ImageContainer 
          ownerState={{
            radius: 8,
            fit: 'cover',
            padding: 'small',
          }}
          sx={{
            width: size,
            height: size,
          }}
        >
          {imageId ? (
            <>
              {imageUrl && (
                <img src={imageUrl} alt={imageUrl} />
              )}
            </>
          ) : (
            <>
              {useIcon(
                useIconNameResolverByDeviceCategoryId(deviceCategoryId),
                {
                // todo: make it a design system color
                //htmlColor: '#f6f6f6',
                  htmlColor: isEven ? palette.secondary.main : palette.primary.main,
                  //htmlColor: '#ffffff',
                  sx: {
                    width: size*0.65,
                    height: size*0.65,
                    opacity: 0.55,
                  },
                }
              )}
            </>
          )}
        </ImageContainer>
      </Box>

    </Block>
  );
};

export default DeviceAvatar;
