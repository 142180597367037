import React, { FC, useEffect } from 'react';
import { IUpdateFuelProps } from '../types';
import {
  useDeviceTechnicalParametersQuery,
  useConsumptionUnitsByFuelTypeQuery,
  useVolumeUnitsByFuelTypeQuery,
} from '@state/mechis-backend/generated/schema';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import useIcon from '@mechis/icons/useIcon';
import { CommonIcons } from '@mechis/icons/types';
import {
  InputAdornment,
  Grid,
  Paper,
  Box,
  Stack,
  FormControlLabel,
  Switch,
  Typography,
  NativeSelect,
  Skeleton as MuiSkeleton,
} from '@mechis/elements';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import {
  blurTarget, 
  ensureNumericZeroIsNotDisplayed,
} from '@utilities/app';
import editFuelCacheControl from '@state/mutations/editFuel';
import { IEditFuelItemModel } from '@state/models/editFuel/types';
import { useReactiveVar } from '@apollo/client';
import editFuel from '@state/models/editFuel';
import useTech from '@hooks/useTech';
import NumberField from '@mechis/elements/NumberField';

interface IForm {
  initialConsumption: number | null
  tankVolume: number | null
}

const Fuel: FC<IUpdateFuelProps> = ({ id }) => {
  const { t } = useTranslation();
  const { techId } = useTech();
  const data = useReactiveVar(editFuel);
  const { data: volumeUnits } = useVolumeUnitsByFuelTypeQuery({
    variables: {
      fuelTypeId: id || 0,
    },
  });
  const { data: consumptionUnits } = useConsumptionUnitsByFuelTypeQuery({
    variables: {
      fuelTypeId: id || 0,
    },
  });
  const {
    loading,
    data: deviceTechnicalParams,
  } = useDeviceTechnicalParametersQuery({
    variables: {
      deviceId: techId,
    },
  });

  const { setFuel } = editFuelCacheControl;
  const fuels = data.fuels;
  const fuel = _.find(fuels, { 
    fuelType: { id },
  }) as IEditFuelItemModel;
  
  const schema = yup.object({
    initialConsumption: yup.number().typeError(''),
    tankVolume: yup.number().typeError(''),
  }).required();

  const { control, handleSubmit } = useForm<IForm>({
    defaultValues: {
      initialConsumption: ensureNumericZeroIsNotDisplayed(fuel?.initialConsumption),
      tankVolume: ensureNumericZeroIsNotDisplayed(fuel?.tankVolume),
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // eslint-disable-next-line
    const actualFuel = deviceTechnicalParams?.device?.fuel.find((f) => f.fuelType?.id == fuel.fuelType?.id);

    if (!!actualFuel) {
      setFuel({
        ...fuel,
        tankVolume: actualFuel.tankVolume,
        initialConsumption: actualFuel?.initialConsumption || 0,
        consumptionUnit: { id: +parseInt(actualFuel.consumptionUnit?.id || '0') },
        volumeUnit: { id: +parseInt(actualFuel.volumeUnit?.id || '0')},
        isSelected: true,
      });
    } else {
      setFuel({
        ...fuel,
        consumptionUnit: {
          id: parseInt(consumptionUnits?.consumptionUnitsByFuelType[0].id || ''),
        },
        volumeUnit: {
          id: parseInt(volumeUnits?.volumeUnitsByFuelType[0].id || ''),
        },
      });
    }
  }, [ deviceTechnicalParams, loading, volumeUnits, consumptionUnits ]);

  const getVolumeUnitsOptions = () => {
    return volumeUnits?.volumeUnitsByFuelType.map((volumeUnitItem) => {
      return (
        <option 
          color="black"
          key={volumeUnitItem.id}
          value={volumeUnitItem.id}
        >
          {volumeUnitItem.unitName}
        </option>
      );
    });
  };

  const getConsumptionUnitsOptions = () => {
    return consumptionUnits?.consumptionUnitsByFuelType.map((consumptionUnitItem) => {
      return (
        <option
          color="black"
          key={consumptionUnitItem.id}
          value={consumptionUnitItem.id}
        >
          {consumptionUnitItem.unitName}
        </option>
      );
    });
  };

  const onSave = async ({ initialConsumption, tankVolume }: IForm) => {
    const changedFuel = {
      ...fuel,
      initialConsumption,
      tankVolume,
    };

    setFuel(changedFuel);
  };

  const onChange = () => {
    const changedFuel = {
      ...fuel,
      isSelected: !fuel?.isSelected,
    };

    setFuel(changedFuel);
  };

  return (
    <Paper variant="outlined">
      <Box py={1} px={1.5}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <FormControlLabel
            control={
              <Switch
                size="medium"
                checked={!!fuel?.isSelected}
                onChange={onChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } 
            label={
              <Typography 
                variant="h5"
                sx={{ 
                  color: (theme) => !!fuel?.isSelected ? theme.palette.primary.main : theme.palette.text.disabled, 
                }}
              >
                {t(`fuelTypes.${fuel.fuelName}`)}
              </Typography>
            } 
          />
          {useIcon(`fuel-${fuel.fuelName}` as CommonIcons, {
            color: fuel.isSelected ? 'primary' : 'disabled',
            sx: {
              fontSize: '1.75em',
            },
          })}
        </Stack>
      
        {fuel.isSelected && (
          <Grid container rowSpacing={2} columnSpacing={1} mt={-1} mb={2}>
            <Grid item xs={7.5}>
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    onKeyUp={() => {
                      handleSubmit(onSave)();
                    }}
                    onWheel={blurTarget}
                    label={t('addDevice.components.initialConsumption.name')}
                    id="initialConsumption"
                    error={!!formState.errors.initialConsumption?.message}
                    helperText={formState.errors.initialConsumption?.message}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DataUsageIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                name="initialConsumption"
                control={control}
              />
            </Grid>
            <Grid item xs={4.5}>
              <NativeSelect
                label={t('addDevice.components.consumptionUnit.name')}
                isLabelWhite
                id="consumptionUnit"
                fullWidth
                value={fuel.consumptionUnit.id}
                onChange={(e) => {
                  const changedFuel = {
                    ...fuel,
                    consumptionUnit: {
                      id: +e.currentTarget.value,
                    },
                  };
                  setFuel(changedFuel);
                }}
              >
                {getConsumptionUnitsOptions()}
              </NativeSelect>
            </Grid>
            <Grid item xs={7.5}>
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    onKeyUp={() => {
                      handleSubmit(onSave)();
                    }}
                    onWheel={blurTarget}
                    label={fuel.fuelName === 'electric' 
                      ? t('addDevice.components.batteryCapacity.name') : t('addDevice.components.tankVolume.name')}
                    id="tankVolume"
                    error={!!formState.errors.tankVolume?.message}
                    helperText={formState.errors.tankVolume?.message}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {useIcon('mechisEngineDisplacement', { fontSize: 'small' })}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                name="tankVolume"
                control={control}
              />
            </Grid>

            <Grid item xs={4.5}>
              <NativeSelect
                label={fuel.fuelName === 'electric' 
                  ? t('addDevice.components.batteryCapacityUnit.name') : t('addDevice.components.volumeUnit.name')}
                isLabelWhite
                id="volumeUnit"
                fullWidth
                value={fuel.volumeUnit.id}
                onChange={(e) => {
                  const changedFuel = {
                    ...fuel,
                    volumeUnit: {
                      id: +e.currentTarget.value,
                    },
                  };
                  setFuel(changedFuel);
                }}
              >
                {getVolumeUnitsOptions()}
              </NativeSelect>
            </Grid>
          </Grid>
        )}
      </Box>
    </Paper>
  );
};

export const Skeleton: FC = () => {
  return (
    <Paper variant="outlined">
      <Box p={2}>
        <MuiSkeleton
          variant="text"
          width="100%"
          height={15}
        />
      </Box>
    </Paper>
  );
};

export default Fuel;
