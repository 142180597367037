import {
  useNavigate as useReactRouterNavigate,
  NavigateOptions,
  To,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { NAVIGATE_MODAL } from '@state/queries/navigateModal';
import { INavigateModalQuery } from '@state/queries/navigateModal/types';
import navigateModalCacheControl from '@state/mutations/navigateModal';
import newMaintenanceCacheControl from '@state/mutations/newMaintenance';
import useExpense from './useExpense';
import { useAttachments } from '@mechis/sections/Attachments';
import selectionsCacheControl from '@state/mutations/selections';

const blockedUrls = [ 
  '/new-maintenance',
  '/new-maintenance/1',
  '/new-maintenance/2',
  '/new-maintenance/3',
  '/new-odometer',
  '/new-expenses',
  '/new-expenses/note',
  '/new-refueling',
  '/new-refueling/note',
];

const formatPathname = (pathname: string): string => {
  const appPrefix = '/app';
  if (pathname.startsWith(appPrefix)) {
    let pathWithoutApp = pathname.slice(appPrefix.length);
    const idPattern = /^\/\d+/;
    if (idPattern.test(pathWithoutApp)) {
      pathWithoutApp = pathWithoutApp.replace(idPattern, '');
    }
    return pathWithoutApp;
  }
  return pathname;
};

const useNavigate = () => {
  const { data } = useQuery<INavigateModalQuery>(NAVIGATE_MODAL);
  const isBlocking = data?.navigateModal.isBlocking;
  const pendingNavigation = data?.navigateModal.pendingNavigation;
  const { setIsBlocking, setPendingNavigation } = navigateModalCacheControl;
  const navigate = useReactRouterNavigate();
  const location = useLocation();
  const pathname = formatPathname(location.pathname);
  const { setOperations, setDescription } = newMaintenanceCacheControl;
  const { resetUseExpenseState } = useExpense();
  const { setSelectedFiles } = useAttachments();
  const { 
    setDateState,
    setDateValue, 
    setOdometerState,
    setOdometerValue,
  } = selectionsCacheControl;
  // eslint-disable-next-line
  const [ searchParams, setSearchParams ] = useSearchParams();

  useEffect(() => {
    const formattedPathname = formatPathname(location.pathname);
    if (blockedUrls.includes(formattedPathname)) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [ location ]);

  const customNavigate = (to: To, options?: NavigateOptions) => {
    if (isBlocking && to !== location.pathname) {
      setPendingNavigation({ to, options });
    } else {
      navigate(to, options);
    }
  };

  const confirmNavigation = () => {
    if (pendingNavigation) {
      if (
        pathname === '/new-maintenance' ||
        pathname === '/new-maintenance/1' ||
        pathname === '/new-maintenance/2' ||
        pathname === '/new-maintenance/3' ||
        pathname === '/new-odometer' ||
        pathname === '/new-expenses' ||
        pathname === '/new-expenses/note' ||
        pathname === '/new-refueling' ||
        pathname === '/new-refueling/note'
      ) {
        setOperations([]);
        setDescription('');
        resetUseExpenseState();
        setDateState('filled');
        setDateValue(new Date());
        setOdometerState('default');
        setOdometerValue(0);
        setSelectedFiles([]);
      }
      navigate(pendingNavigation.to, pendingNavigation.options);
      setPendingNavigation(null);
    }
  };

  const cancelNavigation = () => {
    setPendingNavigation(null);
  };

  return {
    navigate: customNavigate,
    confirmNavigation,
    cancelNavigation,
    isBlocking,
    pendingNavigation,
    setPendingNavigation,
    pathname,
    searchParams,
  };
};

export default useNavigate;
