import React, { FC } from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  Button,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from '@mechis/elements';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NewPlanData, INewPlanProps } from '../types';
import snackbarCacheControl from '@state/mutations/snackbar';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useTech, { UnitType } from '@hooks/useTech';
import NumberField from '@mechis/elements/NumberField';
import { getDaysFromMonths } from '@utilities/dateUtils';

const NewPlan: FC<INewPlanProps> = ({
  open,
  setOpen,
  createMaintenanceInterval,
  errorCreatingInterval,
  onCreated,
}) => {
  const { t } = useTranslation();
  const { techId } = useTech();
  const { setSnackbar } = snackbarCacheControl;
  const { getTechUnit } = useTech();

  const schema = yup
    .object({
      maintenanceName: yup.string().required(t('newMaintenance.form.maintenanceName.required')),
      timeInterval: yup
        .number()
        .transform((value, originalValue) => {
          return originalValue === '' ? null : value;
        })
        .nullable()
        .test(
          'is-not-zero',
          t('newMaintenance.form.distanceInterval.required'),
          (value) => {
            return value === null || value !== 0;
          }
        ),
      distanceInterval: yup
        .number()
        .transform((value, originalValue) => {
          return originalValue === '' ? null : value;
        })
        .nullable()
        .test(
          'is-not-zero',
          t('newMaintenance.form.timeInterval.required'),
          (value) => {
            return value === null || value !== 0;
          }
        ),
    })
    .required();

  // eslint-disable-next-line
  const { control, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      maintenanceName: '',
      timeInterval: null,
      distanceInterval: null,
      operations: [],
    },
    resolver: yupResolver(schema),
  });

  const onCreateNewMaintenance = async (data: NewPlanData) => {
    if (!techId) {
      return;
    }

    const periodDay = data.timeInterval !== null ? getDaysFromMonths(Number(data.timeInterval)) : null;
    const periodDistance = data.distanceInterval !== null ? Number(data.distanceInterval) : null;
    await createMaintenanceInterval({
      variables: {
        maintenanceInterval: {
          device: {
            id: techId,
          },
          name: data.maintenanceName,
          periodDay,
          periodDistance,
        },
      },
    });

    errorCreatingInterval
      ? setSnackbar('error', 'MAINTENANCE_CREATE_ERROR')
      : setSnackbar('success', 'MAINTENANCE_CREATE_SUCCESSFUL');
    setOpen(false);

    onCreated();

    reset({
      maintenanceName: '',
      timeInterval: null,
      distanceInterval: null,
      operations: [],
    });
  };

  return (
    <Dialog onClose={() => setOpen(false)} open={open} maxWidth="xs">
      <Box textAlign="center">
        <DialogContent>
          <Stack spacing={3} direction="column">
            <Typography variant="sectionHeadline">{t('newMaintenance.title')}</Typography>
            <Stack spacing={3} direction="column">
              <Controller
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    label={t('newMaintenance.form.maintenanceName.name')}
                    variant="outlined"
                    type="text"
                    id={'maintenanceName'}
                    error={!!formState.errors.maintenanceName?.message}
                    helperText={
                      <>{formState.errors.maintenanceName?.message}</>
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BuildCircleIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                name="maintenanceName"
                control={control}
              />
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    label={t('newMaintenance.form.timeInterval.name')}
                    variant="outlined"
                    type="text"
                    id={'timeInterval'}
                    error={!!formState.errors.timeInterval?.message}
                    helperText={<>{formState.errors.timeInterval?.message}</>}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonthIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onlyInteger
                  />
                )}
                name="timeInterval"
                control={control}
              />
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    label={t('newMaintenance.form.distanceInterval.name', { unit: getTechUnit(UnitType.Odometer) })}
                    variant="outlined"
                    id={'distanceInterval'}
                    error={!!formState.errors.distanceInterval?.message}
                    helperText={
                      <>{formState.errors.distanceInterval?.message}</>
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SpeedIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onlyInteger
                  />
                )}
                name="distanceInterval"
                control={control}
              />
            </Stack>
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Button onClick={() => setOpen(false)} variant="outlined">
                {t('newMaintenance.button.cancel')}
              </Button>
              <Button
                onClick={handleSubmit(onCreateNewMaintenance)}
                variant="contained"
                color="secondary"
              >
                {t('newMaintenance.button.create')}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default NewPlan;
