import React from 'react';
import {
  useAttachments,
  ContentBlockFunctions,
  ContentBlockFilters,
} from '@mechis/sections/Attachments';
import {
  Stack,
  Chip,
  IconButton,
  Box,
} from '@mechis/elements';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import WindowIcon from '@mui/icons-material/Window';

const AttachmentsContentFilters = () => {
  const {
    filter,
    setFilter,
    view,
    setView,
  } = useAttachments();

  return (
    <Stack direction="row">
      <ContentBlockFilters spacing={1} alignItems="center" direction="row">
        <Chip
          label="Vše"
          variant={filter === 'all' ? 'filled' : 'outlined'}
          color={filter === 'all' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setFilter('all')}
        />
        <Chip
          label="Fotky"
          variant={filter === 'image' ? 'filled' : 'outlined'}
          color={filter === 'image' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setFilter('image')}
        />
        <Chip
          label="Dokumenty"
          variant={filter === 'doc' ? 'filled' : 'outlined'}
          color={filter === 'doc' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setFilter('doc')}
        />
      </ContentBlockFilters>
      <ContentBlockFunctions alignItems="center" direction="row">
        <IconButton onClick={() => setView('list')} sx={{ p: 0.5 }}>
          <Box
            sx={{
              background: view === 'list' ? (theme) => theme.palette.secondary.main : undefined,
              borderRadius: '50%',
              width: '32px',
              height: '32px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ViewStreamIcon 
              htmlColor={view === 'list' ? 'white' : undefined}
              color={view === 'grid' ? 'primary' : undefined}
            />
          </Box>
        </IconButton>
        <IconButton onClick={() => setView('grid')} sx={{ p: 0.5 }}>
          <Box
            sx={{
              background: view === 'grid' ? (theme) => theme.palette.secondary.main : undefined,
              borderRadius: '50%',
              width: '32px',
              height: '32px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <WindowIcon 
              htmlColor={view === 'grid' ? 'white' : undefined}
              color={view === 'list' ? 'primary' : undefined}
            />
          </Box>
        </IconButton>
      </ContentBlockFunctions>
    </Stack>
  );
};

export default AttachmentsContentFilters;
