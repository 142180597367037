import React, { FC } from 'react';
import { useAttachments, IAttachmentsContentProps } from '@mechis/sections/Attachments';
import {
  AttachmentsActionsEventDetail,
  AttachmentsActionsSelectedFiles,
} from '@mechis/sections/Attachments/modal';
import {
  Dialog,
  DialogActions,
  Box,
  Typography,
  DialogContent,
} from '@mechis/elements';
import CancelIcon from '@mui/icons-material/Cancel';
// import CountUp from 'react-countup';

const AttachmentsModal: FC<IAttachmentsContentProps> = ({ children, variant = 'attachments', dialogActions }) => {
  const {
    isModalOpened,
    setIsModalOpen,
    // selectedFilesTotalSize,
  } = useAttachments();
  
  return (
    <Dialog
      open={isModalOpened}
      onClose={() => setIsModalOpen(false)}
      fullScreen
    >
      <CancelIcon
        onClick={() => setIsModalOpen(false)}
        sx={{
          position: 'absolute',
          right: '16px',
          top: '16px',
          color: 'lightgrey',
        }}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Box p={2}>
          <Typography
            variant="h5"
            textAlign="center"
            mb={1}
            mt={1}
          >
            {variant === 'attachments' && 'Přílohy'}
            {variant === 'avatar' && 'Avatar'}
          </Typography>
          <div>{children}</div>
        </Box>
      </DialogContent>
      {variant === 'attachments' && dialogActions !== 'none' && (
        <DialogActions>
          {dialogActions === 'selectedFiles' && <AttachmentsActionsSelectedFiles />}
          {dialogActions === 'eventDetail' && <AttachmentsActionsEventDetail />}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AttachmentsModal;
