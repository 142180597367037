import React, { FC } from 'react';
import { Typography, Stack } from '@mechis/elements';
import NumberFormat from 'react-number-format';
import { THOUSAND_SEPARATOR } from '@configurations/constants/app';
import useTech, { UnitType } from '@hooks/useTech';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import { IIntervalTitleProps } from '../../types';

const IntervalTitle: FC<IIntervalTitleProps> = ({ name, periodDay, periodDistance }) => {
  const { getTechUnit } = useTech();

  return (
    <Stack direction="column" pb={1}>
      <Typography variant="sectionHeadline">{name}</Typography>
      <Stack direction="row" justifyContent={periodDay ? 'space-between' : 'flex-end'}>
        {periodDay ? (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <CalendarMonthIcon color="disabled" fontSize="small" />
            <Typography variant="caption" color={'text.headline'}>
              <NumberFormat value={periodDay} displayType="text" thousandSeparator={THOUSAND_SEPARATOR} suffix={' dnů'} />
            </Typography>
          </Stack>
        ) : undefined}
        {periodDistance ? (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <SpeedIcon color="disabled" fontSize="small" />
            <Typography variant="caption" color={'text.headline'}>
              <NumberFormat
                value={periodDistance}
                displayType="text"
                thousandSeparator={THOUSAND_SEPARATOR}
                suffix={getTechUnit(UnitType.Odometer, true)}
              />
            </Typography>
          </Stack>
        ) : undefined}
      </Stack>
    </Stack>
  );
};

export default IntervalTitle;
