import React, { forwardRef } from 'react';
import { default as MuiButton } from '@mui/material/Button';
import { ButtonProps } from './types';
import { CircularProgress } from '@mechis/elements';

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { color = 'primary', isLoading = false, children, ...other } = props;
  return (
    <MuiButton
      ref={ref}
      color={color}
      disabled={isLoading}
      {...other}
    >
      {isLoading ? <CircularProgress size={22} sx={{ position: 'relative', top: '4px' }} /> : children}
    </MuiButton>
  );
});

Button.displayName = 'Button';

export default Button;
