import React from 'react';
import { IOwnerState } from '../types';
import { Control, ControlLabel } from '../styles';
import {  
  AttachmentsControl,
  AttachmentsModal,
  AttachmentsContent,
  useAttachments,
} from '@mechis/sections/Attachments';
import { IconButton } from '@mechis/elements';
import { getColorByState } from '../Selections';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Attachments = () => {
  const { selectedFiles } = useAttachments();

  const ownerState: IOwnerState = {
    state: (selectedFiles.length > 0) ? 'filled' : 'default',
  };

  return (
    <>
      <AttachmentsControl
        target={selectedFiles.length > 0 ? 'modal' : 'direct'}
        variant="attachments"
      >
        <Control>
          <IconButton
            size="small"
            color={getColorByState(ownerState.state)}
            isSquared
            sx={{ pointerEvents: 'none' }}
          >
            <AttachFileIcon />
          </IconButton>
          <ControlLabel ownerState={ownerState}>
            {selectedFiles.length}
          </ControlLabel>
        </Control>
      </AttachmentsControl>
      <AttachmentsModal variant="attachments" dialogActions="selectedFiles">
        <AttachmentsContent variant="attachmentsSelected" />
      </AttachmentsModal>
    </>
  );
};

export default Attachments;
