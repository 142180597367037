import React, { FC, useState, useEffect } from 'react';
import { Stack, TableRow } from '@mechis/elements';
import SimpleTable from '@mechis/blocks/SimpleTable';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  DeviceBasicInformationDocument,
  DeviceOperationalDataDocument,
  DevicesDocument,
  DeviceUsedFuelsDocument,
  useDeviceBasicInformationQuery,
  useDeviceOperationalDataQuery,
  useDeviceTechnicalParametersQuery,
  useDeviceUsedFuelsQuery,
  useUpdateDeviceBasicInfoMutation, useUpdateDeviceOperationalDataMutation,
  useUpdateDeviceTechnicalParametersMutation,
  useUpdateDeviceUsedFuelsMutation,
} from '@state/mechis-backend/generated/schema';
import ErrorIcon from '@mui/icons-material/Error';
import Loader from '@mechis/blocks/Loader';
import EditBasicInfo from './modals/EditBasicInfo';
import EditTechnicalParams from './modals/EditTechnicalParams';
import UpdateFuel from './modals/UpdateFuel';
import UpdateOperationalData from './modals/UpdateOperationalData';
import { OutputDateFormats } from '@configurations/types/global';

import {
  tableOutputDate,
  tableOutputNumber,
  tableOutputString,
} from '@utilities/app';
import useHeader from '@hooks/useHeader';
import useNavigate from '@hooks/useNavigate';
import Selections from '@mechis/sections/Selections';
import useIcon from '@mechis/icons/useIcon';
import { CommonIcons } from '@mechis/icons/types';

import { CellLabel } from '@mechis/blocks/SimpleTable/styles';
import ControlMenu from '../ControlMenu';
import useTech, { UnitType } from '@hooks/useTech';

const Info: FC = () => {
  useHeader({
    headline: 'appBar.headline.information',
    widget: (
      <Selections>
        <ControlMenu />
      </Selections>
    ),
  });

  const { t } = useTranslation();
  const { techId, getTechUnit } = useTech();
  const [ isFuelUpdateModalOpen, setIsFuelUpdateModalOpen ] = useState<boolean>(false);
  const [ isBasicInfoUpdateModalOpen, setIsBasicInfoUpdateModalOpen ] = useState<boolean>(false);
  const [ isTechnicalParamsUpdateModalOpen, setIsTechnicalParamsUpdateModalOpen ] = useState<boolean>(false);
  const [ isOperationalUpdateModalOpen, setIsOperationalUpdateModalOpen ] = useState<boolean>(false);
  const { searchParams } = useNavigate();

  const {
    data: deviceBasicInformation,
    loading: deviceBasicInformationLoading,
    error: deviceBasicInformationError,
  } = useDeviceBasicInformationQuery({
    variables: {
      deviceId: techId,
    },
  });

  const [
    updateBasicInfo,
    {
      error: updateBasicInfoError,
    },
  ] = useUpdateDeviceBasicInfoMutation({
    refetchQueries: [
      {
        query: DeviceBasicInformationDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });

  const {
    data: deviceTechnicalParams,
    loading: deviceTechnicalParamsLoading,
    error: deviceTechnicalParamsError,
    refetch: refetchTechnicalParams,
  } = useDeviceTechnicalParametersQuery({
    variables: {
      deviceId: techId,
    },
  });

  const [
    updateTechnicalParams,
    {
      error: updateTechnicalParamsError,
    },
  ] = useUpdateDeviceTechnicalParametersMutation({
    onCompleted: () => refetchTechnicalParams({
      deviceId: techId,
    }),
  });

  const {
    data: operationalData,
    loading: operationalDataLoading,
    error: operationalDataError,
  } = useDeviceOperationalDataQuery({
    variables: {
      deviceId: techId,
    },
  });

  const [
    updateOperationalData,
    {
      error: updateOperationalDataError,
    },
  ] = useUpdateDeviceOperationalDataMutation({
    refetchQueries: [
      {
        query: DeviceOperationalDataDocument,
        variables: {
          deviceId: techId,
        },
      },
      {
        query: DevicesDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });

  const {
    data: usedFuelsData,
    loading: usedFuelsLoading,
    error: usedFuelsError,
  } = useDeviceUsedFuelsQuery({
    variables: {
      deviceId: techId,
    },
  });

  const [
    updateDeviceUsedFuels,
    {
      error: updateDeviceUsedFuelsError,
    },
  ] = useUpdateDeviceUsedFuelsMutation({
    refetchQueries: [
      {
        query: DeviceUsedFuelsDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });

  useEffect(() => {
    const openModalFuels = searchParams.get('openModalFuels');
    if (openModalFuels) setIsFuelUpdateModalOpen(true);
  }, [ searchParams ]);

  if (deviceBasicInformationError || deviceTechnicalParamsError || operationalDataError || usedFuelsError) {
    return (
      <ErrorIcon />
    );
  }

  if (deviceBasicInformationLoading || deviceTechnicalParamsLoading || operationalDataLoading || usedFuelsLoading) {
    return (
      <Loader />
    );
  }

  const device = deviceBasicInformation?.device;
  const parameters = deviceTechnicalParams?.device;
  const operations = operationalData?.device;
  const fuels = [ ...usedFuelsData?.device?.fuel ?? [] ].sort((a, b) => parseInt(a?.fuelType?.id || '') - parseInt(b?.fuelType?.id || ''))
    .map((fuel) => ({
      label: t(`fuelTypes.${fuel?.fuelType?.fuelName}`),
      value: useIcon(`fuel-${fuel.fuelType?.fuelName}` as CommonIcons, {
        color: 'primary',
        sx: {
          fontSize: '1.75em',
        },
      }),
    }));

  return (
    <Stack spacing={2}>
      <SimpleTable
        title={t('techDetail.headlines.basicInfo')}
        data={[
          // todo: deviceCategory musí z backendu chodit jako passenger-car a ne passenger car (kvůli slugum v url a lepšímu handlingu)
          { label: t('Kategorie'), value: t(`deviceCategories.${device?.deviceCategory?.name}`) },
          { label: t('addDevice.components.machineNickname.name'), value: tableOutputString(device?.name) },
          { label: t('addDevice.components.manufacturer.name'), value: tableOutputString(device?.manufacturer) },
          { label: t('addDevice.components.model.name'), value: tableOutputString(device?.model) },
          { label: t('addDevice.components.vehicleType.name'), value: String(device?.vehicleType?.name) },
          { label: t('addDevice.components.color.name'), value: tableOutputString(device?.color) },
          {
            label: t('addDevice.components.yearOfManufacture.name'),
            value: tableOutputDate(device?.manufacturedYearMonth, OutputDateFormats.ManufacturedDate),
          },
          { label: t('addDevice.components.vin.name'), value: tableOutputString(device?.vin) },
          { label: t('addDevice.components.licensePlate.name'), value: tableOutputString(device?.spz) },
        ]}
        ctas={[
          {
            title: 'Upravit',
            color: 'secondary',
            onClick: () => setIsBasicInfoUpdateModalOpen(true),
          },
        ]}
      />
      <SimpleTable
        title={t('techDetail.headlines.technicalParameters')}
        data={[
          {
            label: t('addDevice.components.engineSpecification.name'),
            value: tableOutputString(parameters?.engineSpecification),
          },
          {
            label: t('addDevice.components.engineDisplacementCc.name'),
            value: `${tableOutputNumber(parameters?.engineDisplacementCc)} ccm`,
          },
          {
            label: t('addDevice.components.serialNumber.name'),
            value: tableOutputString(parameters?.serialNumber),
          },
          {
            label: t('addDevice.components.power.name'),
            value: `${tableOutputNumber(parameters?.power)} ${tableOutputString(parameters?.powerUnit?.unitName)}
           / ${tableOutputNumber(parameters?.powerRpm)} ot/min`,
          },
          {
            label: t('addDevice.components.torqueNm.name'),
            value: `${tableOutputNumber(parameters?.torqueNm)} Nm ${tableOutputNumber(parameters?.torqueRpm)} ot/min`,
          },
          {
            label: t('addDevice.components.transmission.name'),
            value: `${t(`transmission.${parameters?.transmission?.name}`)}`,
          },
          // TODO: MVP ready
          /*{
            label: t('addDevice.components.transmissionType.name'),
            value: `${tableOutputString(parameters?.transmissionType)}`,
          },*/
          {
            label: t('addDevice.components.transmissionNumber.name'),
            value: `${tableOutputNumber(parameters?.transmissionNumber)}`,
          },
        ]}
        ctas={[
          {
            title: 'Upravit',
            color: 'secondary',
            onClick: () => setIsTechnicalParamsUpdateModalOpen(true),
          },
        ]}
      />
      <SimpleTable
        title={t('techDetail.headlines.fuelInfo')}
        data={fuels}
        emptyState={
          <TableRow key={uuid()}>
            <CellLabel>{t('techDetail.emptyState.fuelInfo')}</CellLabel>
          </TableRow>}
        ctas={[
          {
            title: 'Upravit',
            color: 'secondary',
            onClick: () => setIsFuelUpdateModalOpen(true),
          },
        ]}
      />
      <SimpleTable
        title={t('techDetail.headlines.dataAndWarranty')}
        data={[
          {
            label: t('addDevice.components.firstRegistrationDate.name'),
            value: tableOutputDate(operations?.firstRegistrationDate,
              OutputDateFormats.ManufacturedDate),
          },
          {
            label: t('addDevice.components.acquisitionDate.name'),
            value: tableOutputDate(operations?.acquisitionDate, OutputDateFormats.ManufacturedDate),
          },
          {
            label: t('addDevice.components.initialOdometerState.name'),
            value: `${tableOutputNumber(operations?.initialOdometerState)}${getTechUnit(UnitType.Odometer, true)}`,
          },
          {
            label: t('addDevice.components.guaranteeDate.name'),
            value: tableOutputDate(operations?.guaranteeDate, OutputDateFormats.ManufacturedDate),
          },
          {
            label: t('addDevice.components.guaranteeMonths.name'),
            value: tableOutputNumber(operations?.guaranteeMonths),
          },
          {
            label: t('addDevice.components.guaranteeDistance.name'),
            value: `${tableOutputNumber(operations?.guaranteeDistance)}${getTechUnit(UnitType.Odometer, true)}`,
          },
        ]}
        ctas={[
          {
            title: 'Upravit',
            color: 'secondary',
            onClick: () => setIsOperationalUpdateModalOpen(true),
          },
        ]}
      />
      <EditBasicInfo
        deviceBasicInformation={deviceBasicInformation}
        updateBasicInformation={updateBasicInfo}
        errorBasicInformation={updateBasicInfoError}
        isOpen={isBasicInfoUpdateModalOpen}
        onClose={() => setIsBasicInfoUpdateModalOpen(false)}
      />
      <EditTechnicalParams
        deviceTechnicalParams={deviceTechnicalParams}
        isOpen={isTechnicalParamsUpdateModalOpen}
        onClose={() => setIsTechnicalParamsUpdateModalOpen(false)}
        updateTechnicalParams={updateTechnicalParams}
        errorTechnicalParams={updateTechnicalParamsError}
      />
      <UpdateFuel
        isOpen={isFuelUpdateModalOpen}
        onClose={() => setIsFuelUpdateModalOpen(false)}
        usedFuelsData={usedFuelsData}
        updateUsedFuels={updateDeviceUsedFuels as any}
        errorUpdateUsedFuels={updateDeviceUsedFuelsError}
      />
      <UpdateOperationalData
        deviceOperationalData={operationalData}
        isOpen={isOperationalUpdateModalOpen}
        onClose={() => setIsOperationalUpdateModalOpen(false)}
        updateOperationalData={updateOperationalData}
        errorOperationalData={updateOperationalDataError}
      />
    </Stack>
  );
};

export default Info;
