import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  Button,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from '@mechis/elements';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IUpdatePlanProps } from '../types';
import ConfirmationModal from '@mechis/blocks/ConfirmationModal';
import snackbarCacheControl from '@state/mutations/snackbar';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import useTech, { UnitType } from '@hooks/useTech';
import NumberField from '@mechis/elements/NumberField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDaysFromMonths, getMonthsFromDays } from '@utilities/dateUtils';

const UpdatePlan: FC<IUpdatePlanProps> = ({
  open,
  setOpen,
  updateMaintenanceInterval,
  deleteMaintenanceInterval,
  errorUpdateMaintenance,
  maintenanceData,
  onDeleted,
}) => {
  const { t } = useTranslation();
  const { techId, getTechUnit } = useTech();
  const { setSnackbar } = snackbarCacheControl;

  const schema = yup
    .object({
      maintenanceName: yup.string().required(t('newMaintenance.form.maintenanceName.required')),
      timeInterval: yup
        .number()
        .transform((value, originalValue) => {
          return originalValue === '' ? null : value;
        })
        .nullable()
        .test(
          'is-not-zero',
          t('newMaintenance.form.distanceInterval.required'),
          (value) => {
            return value === null || value !== 0;
          }
        ),
      distanceInterval: yup
        .number()
        .transform((value, originalValue) => {
          return originalValue === '' ? null : value;
        })
        .nullable()
        .test(
          'is-not-zero',
          t('newMaintenance.form.timeInterval.required'),
          (value) => {
            return value === null || value !== 0;
          }
        ),
    })
    .required();

  const [ isConfirmationModalOpened, setIsConfirmationModalOpened ] = useState(false);

  // eslint-disable-next-line
  const { control, handleSubmit, formState: { isDirty, dirtyFields, isSubmitting }, reset } = useForm<any>({
    defaultValues: {
      maintenanceName: maintenanceData?.name,
      timeInterval: maintenanceData?.periodDay !== null ? getMonthsFromDays(Math.ceil(maintenanceData?.periodDay ?? 0)) : null,
      distanceInterval: maintenanceData?.periodDistance,
    },
    resolver: yupResolver(schema),
  });
  
  useEffect(() => {
    reset({
      maintenanceName: maintenanceData?.name,
      timeInterval: maintenanceData?.periodDay !== null ? getMonthsFromDays(Math.ceil(maintenanceData?.periodDay ?? 0)) : null,
      distanceInterval: maintenanceData?.periodDistance,
    });
  }, [ maintenanceData?.name ]);

  const onUpdateMaintenance = async (data: any) => {
    if (!techId || !maintenanceData) {
      return;
    }

    const periodDay = data.timeInterval !== null ? getDaysFromMonths(Number(data.timeInterval)) : null;
    const periodDistance = data.distanceInterval !== null ? Number(data.distanceInterval) : null;
    await updateMaintenanceInterval({
      variables: {
        maintenanceInterval: {
          id: Number(maintenanceData.id),
          name: data.maintenanceName,
          periodDay,
          periodDistance,
          device: {
            id: techId,
          },
        },
      },
    });

    errorUpdateMaintenance ? setSnackbar('error', 'MAINTENANCE_UPDATE_ERROR') : setSnackbar('success', 'MAINTENANCE_UPDATE_SUCCESSFUL');
    setOpen(false);
  };

  const onDeleteMaintenance = async () => {
    await deleteMaintenanceInterval({
      variables: {
        deleteMaintenanceIntervalId:  Number(maintenanceData?.id),
      },
    });

    errorUpdateMaintenance ? setSnackbar('error', 'MAINTENANCE_DELETE_ERROR') : setSnackbar('success', 'MAINTENANCE_DELETE_SUCCESSFUL');
    setIsConfirmationModalOpened(false);
    setOpen(false);
    onDeleted();
  };

  return (
    <Fragment>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        maxWidth="xs"
      >
        <Box textAlign="center">
          <DialogContent>
            <Stack spacing={3} direction="column">
              <Typography variant="sectionHeadline">{t('updateMaintenance.title')}</Typography>
              <Stack spacing={3} direction="column">
                <Controller
                  render={({ field, formState }) => (
                    <TextField
                      {...field}
                      label={t('newMaintenance.form.maintenanceName.name')}
                      variant="outlined"
                      type="text"
                      id={'maintenanceName'}
                      error={!!formState.errors.name?.message}
                      helperText={<>{formState.errors.name?.message}</>}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BuildCircleIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  name="maintenanceName"
                  control={control}
                />
                <Controller
                  render={({ field, formState }) => (
                    <NumberField
                      {...field}
                      label={t('newMaintenance.form.timeInterval.name')}
                      variant="outlined"
                      type="text"
                      id={'timeInterval'}
                      error={!!formState.errors.timeInterval?.message}
                      helperText={<>{formState.errors.timeInterval?.message}</>}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarMonthIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      onlyInteger
                    />
                  )}
                  name="timeInterval"
                  control={control}
                />
                <Controller
                  render={({ field, formState }) => (
                    <NumberField
                      {...field}
                      label={t('newMaintenance.form.distanceInterval.name', { unit: getTechUnit(UnitType.Odometer) })}
                      variant="outlined"
                      id={'distanceInterval'}
                      error={!!formState.errors.distanceInterval?.message}
                      helperText={<>{formState.errors.distanceInterval?.message}</>}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SpeedIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      onlyInteger
                    />
                  )}
                  name="distanceInterval"
                  control={control}
                />
              </Stack>

              <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  onClick={() => setOpen(false)}
                  variant="outlined"
                >
                  {t('newMaintenance.button.cancel')}
                </Button>
                <Button
                  onClick={handleSubmit(onUpdateMaintenance)}
                  variant="contained"
                  color="secondary"
                >
                  {t('newMaintenance.button.save')}
                </Button>
              </Stack>
              <Button
                variant="outlined"
                onClick={() => setIsConfirmationModalOpened(true)} 
                color="error"
                sx={{ alignSelf: 'center' }}
              >
                {t('buttons.delete')}
              </Button>
            </Stack>
          </DialogContent>
        </Box>
      </Dialog>

      <ConfirmationModal 
        isOpen={isConfirmationModalOpened} 
        onConfirm={onDeleteMaintenance} 
        onClose={() => setIsConfirmationModalOpened(false)}
        content={t('newMaintenance.confirmationModal.content')}
        confirmButtonText={t('buttons.delete')}
      />
    </Fragment>
  );
};

export default UpdatePlan;
