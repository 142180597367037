import React, { useState, useEffect } from 'react';
import { useAttachments, AttachmentAllowedType } from '@mechis/sections/Attachments';
import {
  AttachmentsConfirmDeleteModal,
  AttachmentsGridItem,
  AttachmentsListItem,
  AttachmentsLightboxItem,
} from '@mechis/sections/Attachments/components';
import {
  List,
  Grid,
  Box,
} from '@mechis/elements';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '@mechis/blocks/Loader';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const AttachmentsFetched = () => {
  const {
    isLast,
    view,
    attachments,
    fetchMoreAttachments,
    loadAttachments,
    setIsDeleteIntent,
  } = useAttachments();

  useEffect(() => {
    loadAttachments();
  }, []);

  const [ lightBoxOpened, setLightBoxOpened ] = useState<boolean>(false);
  const [ clickedImageIndex, setClickedImageIndex ] = useState<number>(0);

  const imageSlides = (attachments ?? [])
    .filter((attachment) => attachment?.mimeType?.startsWith('image/'))
    .map((attachment) => ({
      src: attachment.storageName || '',
    }));

  const onClick = (storageName: string) => {
    if (attachments) {
      setClickedImageIndex(
        attachments
          .filter((attachment) => attachment?.mimeType?.startsWith('image/'))
          .findIndex((attachment) => attachment.storageName === storageName)
      );
      setLightBoxOpened(true);
    }
  };

  if (attachments?.length === 0) return null;

  if (view === 'list') {
    return (
      <>
        <AttachmentsConfirmDeleteModal />
        <InfiniteScroll
          dataLength={attachments?.length || 0}
          next={fetchMoreAttachments}
          hasMore={!isLast}
          loader={<Loader size={20} />}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableDiv"
        >
          <List>
            {attachments?.map((file, index) => {
              return (
                <AttachmentsListItem
                  key={index}
                  variant="fetched"
                  src={file.storageName || ''}
                  alt={file.originalName || ''}
                  type={file.mimeType as AttachmentAllowedType}
                  name={file.originalName || ''}
                  onClick={() => onClick(file.storageName || '')}
                  onDelete={() => setIsDeleteIntent(file)}
                />
              );
            })}
          </List>
          <Lightbox
            open={lightBoxOpened}
            close={() => setLightBoxOpened(false)}
            index={clickedImageIndex}
            slides={imageSlides}
            render={{ slide: ({ slide }) => <AttachmentsLightboxItem storageName={slide?.src || ''} /> }}
          />
        </InfiniteScroll>
      </>
    );
  }

  if (view === 'grid') {
    return (
      <Box mt={2}>
        <InfiniteScroll
          dataLength={attachments?.length || 0}
          next={fetchMoreAttachments}
          hasMore={!isLast}
          loader={<Loader size={20} />}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableDiv"
        >
          <Grid container spacing={2}>
            {attachments?.map((file, index) => {
              return (
                <AttachmentsGridItem
                  key={index}
                  variant="fetched"
                  src={file.storageName || ''}
                  alt={file.originalName || ''}
                  type={file.mimeType as AttachmentAllowedType}
                  name={file.originalName || ''}
                  onClick={() => onClick(file.storageName || '')}
                />
              );
            })}
            <Lightbox
              open={lightBoxOpened}
              close={() => setLightBoxOpened(false)}
              index={clickedImageIndex}
              slides={imageSlides}
              render={{ slide: ({ slide }) => <AttachmentsLightboxItem storageName={slide?.src || ''} /> }}
            />
          </Grid>
        </InfiniteScroll>
      </Box>
    );
  }

  return null;
};

export default AttachmentsFetched;

