import React, { FC } from 'react';
import { IProps } from './types';
import {
  Block,
  Content,
  Title,
} from './styles';
import { 
  Paper,
  Box,
  Typography,
} from '@mechis/elements';
import Vrp from '@mechis/blocks/Vrp';
import DeviceAvatar from '../DeviceAvatar/DeviceAvatar';
import NumberFormat from 'react-number-format';
import { NON_BREAKING_SPACE, THOUSAND_SEPARATOR } from '@configurations/constants/app';
import { getDeleteDateFromSuspendedDate } from '@utilities/getDeleteDateFromSuspendedDate';
import { useTranslation } from 'react-i18next';

const TechCard: FC<IProps> = ({ 
  title,
  vrp,
  imageId,
  attachmentId,
  onClick,
  serialNumber,
  deviceCategoryId,
  isEven = true,
  odometerState,
  odometerUnit,
  suspendedDate,
}) => {
  const { t } = useTranslation();

  return (
    <Block onClick={onClick}>
      <Box>
        <DeviceAvatar
          imageId={imageId}
          attachmentId={attachmentId}
          deviceCategoryId={deviceCategoryId}
          size={60}
          isEven={isEven}
        />
      </Box>
      <Content ml={2}>
        <Paper sx={{ minHeight: '62px' }}>
          <Box px={2} py={1.5} sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Box>
              <Title 
                variant="h5"
                sx={{ 
                  color: (theme) => theme.palette.primary.main,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '130px',
                }}
              >
                {title}
              </Title>
              <Typography sx={{ fontSize: '0.75em', color: (theme) => theme.palette.divider }}>
                {odometerState ? (<NumberFormat 
                  value={odometerState}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  suffix={odometerUnit ? `${NON_BREAKING_SPACE}${odometerUnit}` : ''}
                />) : <>{odometerUnit ? `0${NON_BREAKING_SPACE}${odometerUnit}` : '0'}</>}
              </Typography>
              {suspendedDate && (
                <Typography sx={{ fontSize: '0.75em', color: (theme) => theme.palette.error.main }}>
                  {t('techCard.willBeDeleted')}{getDeleteDateFromSuspendedDate(suspendedDate)}
                </Typography>
              )}
            </Box>
            <Vrp
              variant="default"
              vrp={vrp}
              serialNumber={serialNumber}
            />
          </Box>
        </Paper>
      </Content>
    </Block>
  );
};

export default TechCard;
