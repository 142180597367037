import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { 
  useCreateMaintenanceEventMutation,
  useCreateMaintenanceIntervalMutation,
  useCreateOperationMutation,
  useDeleteMaintenanceIntervalMutation,
  useDeleteOperationMutation,
  useMaintenanceIntervalsByDeviceQuery,
  useUpdateMaintenanceIntervalMutation,
  useUpdateOperationMutation,
  useMaintenanceByEventIdLazyQuery,
  useUpdateMaintenanceEventMutation,
  MaintenanceByEventIdDocument,
  TagsOrderedByUsageDocument,
  MaintenanceIntervalsByDeviceDocument,
} from '@state/mechis-backend/generated/schema';
import { GET_NEW_MAINTENANCE } from '@state/queries/newMaintenance';
import { GET_SELECTIONS } from '@state/queries/selections';
import useExpense from './useExpense';
import { EVENT_TYPE_MAINTENANCE } from '@configurations/constants/eventTypes';
import _ from 'lodash';
import { CURRENCY_DEFAULT_ID, TAGS_BY_USAGE_LIMIT } from '@configurations/constants/app';
import { ITagOption } from '@mechis/sections/Selections/TagFilters/types';
import newMaintenanceCacheControl from '@state/mutations/newMaintenance';
import selectionsCacheControl from '@state/mutations/selections';
import { useAttachments } from '@mechis/sections/Attachments';
import snackbarCacheControl from '@state/mutations/snackbar';
import useTech from './useTech';

interface IProps {
  eventId?: string
  keepOperations?: boolean
}

const useMaintenance = ({ eventId, keepOperations }: IProps) => {
  const { techId } = useTech();
  const { setSnackbar } = snackbarCacheControl;
  const navigate = useNavigate();
  const { expenses, prices, priceTotal } = useExpense();
  const { data: newMaintenance } = useQuery(GET_NEW_MAINTENANCE);
  const { data: selections } = useQuery(GET_SELECTIONS);
  const [ isUploading, setIsUploading ] = useState<boolean>(false);
  const [ createMaintenanceEvent ] = useCreateMaintenanceEventMutation({
    refetchQueries: [
      {
        query: TagsOrderedByUsageDocument,
        variables: {
          limit: TAGS_BY_USAGE_LIMIT,
        },
      },
      {
        query: MaintenanceIntervalsByDeviceDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });
  const { resetMutationsInputSelections } = selectionsCacheControl;
  const { uploadFiles, setSelectedFiles } = useAttachments();

  const odometer = selections?.selections?.odometerValue;
 
  const date = selections?.selections?.dateValue;
  const description = newMaintenance?.newMaintenance?.description;
  const operations = newMaintenance?.newMaintenance?.operations;
  const expanded = newMaintenance?.newMaintenance?.expanded;

  const {
    data: maintenanceIntervals,
    // loading: isLoadingMaintenanceInterval,
    // error: isErrorMaintenanceInterval,
    refetch: refetchMaintenanceIntervals,
  } = useMaintenanceIntervalsByDeviceQuery({
    variables: {
      deviceId: techId,
    },
  });

  const [ getEventIntervals, { data: eventIntervals } ] = useMaintenanceByEventIdLazyQuery({
    variables: {
      eventId: Number(eventId),
    },
  });

  const {
    setOperations,
    setDescription,
    setExpanded,
  } = newMaintenanceCacheControl;

  const clearedOperations = () => {
    const uniqueSet = new Set(operations);
    const uniqueArray = Array.from(uniqueSet);
    const finalArray = uniqueArray.map((id) => {
      return {
        operation: {
          id,
        },
      };
    });
    return finalArray;
  };

  const onCreateMaintenanceEvent = async () => {
    if (techId) {
      setIsUploading(true);
      let attachmentIds = [];
      const uploadedFiles = await uploadFiles();
      if (uploadedFiles) {
        const ids = uploadedFiles.map((attachment) => attachment.id);
        attachmentIds = ids;
      }
  
      const result = await createMaintenanceEvent({
        variables: {
          event: {
            // @ts-ignore
            device: { id: +techId },
            name: 'Údržba',
            eventType: { id: EVENT_TYPE_MAINTENANCE },
            priceTotal,
            eventDate: date,
            stateOdometer: odometer,
            description,
            // @ts-ignore
            eventOperation: clearedOperations(),
          },
          attachmentIds,
          expenses: expenses.map((expense: ITagOption) => {
            return {
              tag: {
                id: +expense.id,
              },
              currency: {
                id: CURRENCY_DEFAULT_ID,
              },
              price: _.filter(prices, { tagId: expense.tagId })[0].price,
            };
          }),
        },
      });

      if (result.data !== null && result.data !== undefined) {
        setSnackbar('success', 'MAINTENANCE_EVENT_SAVED');
        resetMutationsInputSelections();
        setSelectedFiles([]);
        navigate(`/app/${techId}/tech-detail/records`);
        setIsUploading(false);
      }
    }
  };

  const [ createMaintenanceInterval, { error: errorCreatingInterval } ] = useCreateMaintenanceIntervalMutation({
    onCompleted: () => refetchMaintenanceIntervals({
      deviceId: techId,
    }),
  });

  const [ createOperation, { error: errorCreateOperation } ] = useCreateOperationMutation({
    onCompleted: () => refetchMaintenanceIntervals({
      deviceId: techId,
    }),
  });

  const [ updateOperation, { error: errorUpdateOperation } ] = useUpdateOperationMutation({
    onCompleted: () => refetchMaintenanceIntervals({
      deviceId: techId,
    }),
  });

  const [ deleteOperation, { error: errorDeleteOperation } ] = useDeleteOperationMutation({
    onCompleted: () => refetchMaintenanceIntervals({
      deviceId: techId,
    }),
  });

  const [ updateMainMaintenanceInterval, { error: errorUpdateMaintenanceInterval } ] = useUpdateMaintenanceIntervalMutation({
    onCompleted: () => refetchMaintenanceIntervals({
      deviceId: techId,
    }),
  });

  const [ deleteMaintenanceInterval, { error: errorDeleteMaintenanceInterval } ] = useDeleteMaintenanceIntervalMutation({
    onCompleted: () => refetchMaintenanceIntervals({
      deviceId: techId,
    }),
  });

  const [ updateMaintenanceEventMutation, { error: errorUpdateMaintenanceEvent } ] = useUpdateMaintenanceEventMutation({
    refetchQueries: [
      {
        query: TagsOrderedByUsageDocument,
        variables: {
          limit: TAGS_BY_USAGE_LIMIT,
        },
      },
      {
        query: MaintenanceIntervalsByDeviceDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });

  const onEditMaintenanceEvent = async () => {
    const operationsMap = operations.map((operationId: number) => {
      return {
        operation: {
          id: operationId,
        },
      };
    });

    await updateMaintenanceEventMutation({
      variables: {
        event: {
          id: Number(eventId),
          eventOperation: operationsMap,
        },
      },
      refetchQueries: [ {
        query: MaintenanceByEventIdDocument,
        variables: {
          eventId: Number(eventId),
        },
      } ],
    });
  };

  useEffect(() => {
    const fetchEventIntervals = async () => {
      try {
        const { data } = await getEventIntervals();
        const savedOperations =
          data?.maintenanceIntervalsByEvent?.flatMap(
            (interval) => interval?.operation?.filter((operation) => !operation.isRemoved).map((operation) => Number(operation.id)) || []
          ) || [];
        setOperations(savedOperations);
      } catch (error) {
        //silent
      }
    };

    if (eventId) fetchEventIntervals();
    else if (!keepOperations) setOperations([]);
  }, [ eventId, keepOperations ]);

  return {
    operations,
    setOperations,
    description,
    setDescription,
    expanded,
    setExpanded,
    onCreateMaintenanceEvent,
    expenses,
    prices,
    clearedOperations: clearedOperations(),
    maintenanceIntervals: maintenanceIntervals?.maintenanceIntervalsByDevice,
    refetchMaintenanceIntervals,
    createMaintenanceInterval,
    errorCreatingInterval,
    createOperation,
    errorCreateOperation,
    updateOperation,
    errorUpdateOperation,
    deleteOperation,
    errorDeleteOperation,
    updateMainMaintenanceInterval,
    errorUpdateMaintenanceInterval,
    deleteMaintenanceInterval,
    errorDeleteMaintenanceInterval,
    eventIntervals: eventIntervals?.maintenanceIntervalsByEvent,
    onEditMaintenanceEvent,
    errorUpdateMaintenanceEvent,
    isUploading,
  };
};

export default useMaintenance;
