import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import InputAdornment from '@mechis/elements/InputAdornment';
import { yupResolver } from '@hookform/resolvers/yup';
import snackbarCacheControl from '@state/mutations/snackbar';
import { IEditTechnicalParamsProps, IEditTechnicalParamsFormInput } from './types';
import {useGetTransmissionOptions} from '@hooks/deviceHooks/useGetTransmissionOptions';
import NativeSelect from '@mechis/elements/NativeSelect';
import { blurTarget, ensureNumericZeroIsNotDisplayed } from '@utilities/app';
import TextField from '@mechis/elements/TextField';
import UpdateInfoModalHeader from '@screens/TechDetail/Info/modals/UpdateInfoModalHeader';
import useTech from '@hooks/useTech';
import useUnits from '@hooks/useUnits';
import NumberField from '@mechis/elements/NumberField';
import SpeedIcon from '@mui/icons-material/Speed';
import useIcon from '@mechis/icons/useIcon';

const EditTechnicalParams: React.FC<IEditTechnicalParamsProps> = ({
  isOpen, 
  onClose, 
  deviceTechnicalParams, 
  updateTechnicalParams, 
  errorTechnicalParams, 
}) => {
  const { t } = useTranslation();
  const { techId } = useTech();
  const [ powerUnit, setPowerUnit ]
    = useState<number | undefined>(Number(deviceTechnicalParams?.device?.powerUnit?.id));
  const { setSnackbar } = snackbarCacheControl;
  const getTransmissionOptions = useGetTransmissionOptions();
  const { powerUnitsData } = useUnits();
  const isPowerUnitValueSame = Number(deviceTechnicalParams?.device?.powerUnit?.id) === powerUnit;

  const schema = yup.object({
    engineSpecification: yup.string(),
    serialNumber: yup.string(),
    engineDisplacementCc: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
    power: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
    powerUnit: yup.string(),
    powerRpm: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
    torqueNm: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
    torqueRpm: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
    transmission: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
    transmissionNumber: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
  }).required();

  const { control, handleSubmit, formState: { isDirty, isSubmitting }, reset} = useForm<IEditTechnicalParamsFormInput>({
    defaultValues: {
      engineSpecification: deviceTechnicalParams?.device?.engineSpecification,
      serialNumber: deviceTechnicalParams?.device?.serialNumber,
      engineDisplacementCc: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.engineDisplacementCc),
      // TODO: ?? hack, více informací v MECH-390
      power: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.power ?? 0),
      powerUnit: String(powerUnit),
      powerRpm: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.powerRpm),
      torqueNm: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.torqueNm),
      torqueRpm: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.torqueRpm),
      transmission: deviceTechnicalParams?.device?.transmission?.id,
      transmissionNumber: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.transmissionNumber),
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      engineSpecification: deviceTechnicalParams?.device?.engineSpecification,
      serialNumber: deviceTechnicalParams?.device?.serialNumber,
      engineDisplacementCc: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.engineDisplacementCc),
      power: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.power ?? 0),
      powerUnit: String(powerUnit),
      powerRpm: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.powerRpm),
      torqueNm: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.torqueNm),
      torqueRpm: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.torqueRpm),
      transmission: deviceTechnicalParams?.device?.transmission?.id,
      transmissionNumber: ensureNumericZeroIsNotDisplayed(deviceTechnicalParams?.device?.transmissionNumber),
    });
  }, [ deviceTechnicalParams, reset ]);

  const onChangeTechnicalParams = async (data: any) => {
    await updateTechnicalParams({
      variables: {
        device: {
          id: techId,
          engineSpecification: data.engineSpecification,
          serialNumber: data.serialNumber,
          engineDisplacementCc: data.engineDisplacementCc,
          power: data.power,
          powerUnit: { id: Number(powerUnit) },
          powerRpm: data.powerRpm,
          torqueNm: data.torqueNm,
          torqueRpm: data.torqueRpm,
          transmission: { id: Number(data.transmission) },
          transmissionNumber: data.transmissionNumber,
        },
      },
    });
    errorTechnicalParams ? setSnackbar('error', 'DEVICE_UPDATE_FAILED') : setSnackbar('success', 'DEVICE_UPDATE_SUCCESS');
    onClose();
  };

  const isSubmitButtonDisabled = !isDirty && isPowerUnitValueSame && !isSubmitting;

  return (
    <Dialog
      maxWidth="xs"
      fullScreen={true}
      open={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
    >
      <DialogContent>
        <UpdateInfoModalHeader
          onClose={onClose}
          tParam="techDetail.headlines.technicalParameters"
        />
        <Box textAlign="center">
          <Box mb={2}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  id="engineSpecification"
                  label={t('addDevice.components.engineSpecification.name')}
                  variant="outlined"
                  error={!!formState.errors.engineSpecification?.message}
                  helperText={<>{formState.errors.engineSpecification?.message}</>}
                />
              )}
              name="engineSpecification"
              control={control}
            />
          </Box>
          <Box mb={2}>
            <Controller
              render={({ field, formState }) => (
                <NumberField
                  {...field}
                  id="engineDisplacementCc"
                  label={t('addDevice.components.engineDisplacementCc.name')}
                  error={!!formState.errors.engineDisplacementCc?.message}
                  helperText={<>{formState.errors.engineDisplacementCc?.message}</>}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">cm³</InputAdornment>,
                  }}
                  variant="outlined"
                />
              )}
              name="engineDisplacementCc"
              control={control}
            />
          </Box>
          <Box mb={2}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  id="serialNumber"
                  label={t('addDevice.components.serialNumber.name')}
                  error={!!formState.errors.serialNumber?.message}
                  helperText={<>{formState.errors.serialNumber?.message}</>}
                  variant="outlined"
                />
              )}
              name="serialNumber"
              control={control}
            />
          </Box>
          <Box display="flex" gap={2} mb={2}>
            <Box flex={1}>
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    onWheel={blurTarget}
                    label={t('addDevice.components.power.name')}
                    id="power"
                    error={!!formState.errors.power?.message}
                    helperText={formState.errors.power?.message}
                    units={powerUnitsData?.powerUnits.map((unit) => ({
                      id: Number(unit.id),
                      name: unit.unitName,
                      isSelected: powerUnit === Number(unit.id),
                    }))}
                    onUnitChange={(unitId) => setPowerUnit(unitId)}
                  />
                )}
                name="power"
                control={control}
              />
            </Box>
            <Box flex={1}>
              <Controller
                render={({field, formState}) => (
                  <NumberField
                    {...field}
                    id="powerRpm"
                    label={t('addDevice.components.powerRpm.name')}
                    error={!!formState.errors.powerRpm?.message}
                    helperText={<>{formState.errors.powerRpm?.message}</>}
                    InputProps={{
                      endAdornment:
                      <InputAdornment position="end">
                        {t('addDevice.components.powerRpm.endAdornment')}
                      </InputAdornment>,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SpeedIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onlyInteger
                  />
                )}
                name="powerRpm"
                control={control}
              />
            </Box>
          </Box>
          <Box display="flex" gap={2} mb={2}>
            <Box flex={1}>
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    id="torqueNm"
                    label={t('addDevice.components.torqueNm.name')}
                    error={!!formState.errors.torqueNm?.message}
                    helperText={<>{formState.errors.torqueNm?.message}</>}
                    InputProps={{
                      endAdornment: 
                      <InputAdornment position="end">
                        {t('addDevice.components.torqueNm.endAdornment')}
                      </InputAdornment>,
                      startAdornment: (
                        <InputAdornment position="start">
                          {useIcon('mechisTorque', { fontSize: 'small' })}
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                )}
                name="torqueNm"
                control={control}
              />
            </Box>
            <Box flex={1}>
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    id="torqueRpm"
                    label={t('addDevice.components.torqueRpm.name')}
                    error={!!formState.errors.torqueRpm?.message}
                    helperText={<>{formState.errors.torqueRpm?.message}</>}
                    InputProps={{
                      endAdornment:
                      <InputAdornment position="end">
                        {t('addDevice.components.torqueRpm.endAdornment')}
                      </InputAdornment>,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SpeedIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onlyInteger
                  />
                )}
                name="torqueRpm"
                control={control}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" mb={2} gap={2}>
          <Box flex={1}>
            <Controller
              render={({ field, formState }) => (
                <NativeSelect
                  {...field}
                  isLabelWhite
                  label={t('addDevice.components.transmission.name')}
                  fullWidth
                  id="transmission"
                  error={!!formState.errors.transmission?.message}
                >
                  {getTransmissionOptions}
                </NativeSelect>
              )}
              name="transmission"
              control={control}
            />
          </Box>
          <Box flex={1}>
            <Controller
              render={({ field, formState }) => (
                <NumberField
                  {...field}
                  label={t('addDevice.components.transmissionNumber.name')}
                  id="transmissionNumber"
                  error={!!formState.errors.transmissionNumber?.message}
                  helperText={<>{formState.errors.transmissionNumber?.message}</>}
                  variant="outlined"
                  onlyInteger
                />
              )}
              name="transmissionNumber"
              control={control}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            onClick={handleSubmit(onChangeTechnicalParams)}
            disabled={isSubmitButtonDisabled}
          >
            {t('myProfile.button.saveChanges')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditTechnicalParams;
