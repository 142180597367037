import React, { FC, useEffect, useState } from 'react';
import { Event, Filter, useEventsByDeviceAndFiltersAndTagsQuery } from '@state/mechis-backend/generated/schema';
import ErrorIcon from '@mui/icons-material/Error';
import Loader from '@mechis/blocks/Loader';
import { IProps } from './types';
import Record from '@mechis/blocks/Record';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollButton from '@mechis/blocks/ScrollButton';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';
import { filterSelectionToQuery, isFilterUsed } from '@hooks/utils';
import { isObjectEmpty } from '@utilities/app';
import RecordListBlock from '@mechis/blocks/RecordList';
import { EVENT_TYPE_REFUELING } from '@configurations/constants/eventTypes';
import { compareDesc } from 'date-fns';
import { EventType } from '@configurations/constants/eventTypes';
import { Skeleton, Stack } from '@mechis/elements';
import QuoteBlock from '@mechis/blocks/QuoteBlock';
import { useTranslation } from 'react-i18next';

const RecordList: FC<IProps> = ({ isWidget = false }) => {
  const { t } = useTranslation();
  const { techId } = useParams();
  // todo: fix limit, it seems its not working properly
  const LIMIT = isWidget ? 5 : 10;
  const [ offset, setOffset ] = useState<number>(0);
  const [ eventsToRender, setEventsToRender ] = useState<Event[]>([]);
  const [ tags, setTags ] = useState<number[]>([]);
  const [ filters, setFilters ] = useState<Filter | undefined>(undefined);
  const { data: selections } = useQuery<ISelectionsQuery>(GET_SELECTIONS);

  const { data: events, loading: eventsLoading, error: eventsError } = useEventsByDeviceAndFiltersAndTagsQuery({
    fetchPolicy: 'network-only',
    variables: {
      deviceId: Number(techId),
      offset,
      limit: LIMIT,
      filters,
      tags,
    },
  });

  const sortEvents = (first: Event, second: Event): number => {
    const primarySortResult = compareDesc(first.eventDate ?? '', second.eventDate ?? '');

    if (primarySortResult === 0) {
      if (!first?.stateOdometer || !second?.stateOdometer) return primarySortResult;
      if (first?.stateOdometer < second?.stateOdometer) return -1;
      if (first?.stateOdometer > second?.stateOdometer) return 1;
    }

    return primarySortResult;
  };

  useEffect(() => {
    if (!selections?.selections.filterIsModalOpen) {
      if (!isObjectEmpty(filterSelectionToQuery(selections?.selections))) {
        setOffset(0);
        setEventsToRender([]);
      }
      setFilters(filterSelectionToQuery(selections?.selections));
    }

    if (selections?.selections?.tags && selections?.selections?.tags.length > 0) {
      setTags(selections?.selections?.tags.map((tag) => Number(tag.id)));
      setOffset(0);
      setEventsToRender([]);
    }

  }, [ selections?.selections ]);

  useEffect(() => {
    if (events && events.eventsByDeviceAndFiltersAndTags) {
      if (isWidget) {
        setEventsToRender([ ...events?.eventsByDeviceAndFiltersAndTags?.event as Event[] ]);
        return;
      }

      if (offset === 0 && filters) {
        setEventsToRender([ ...events?.eventsByDeviceAndFiltersAndTags?.event as Event[] ]);
        return;
      }
      
      setEventsToRender([ ...eventsToRender, ...events?.eventsByDeviceAndFiltersAndTags?.event as Event[] ]);
    }
  }, [ events, eventsLoading, eventsError, selections?.selections ]);

  const fetchMoreData = () => {
    setOffset(offset + LIMIT);
  };

  const renderEvents = () => {
    return eventsToRender
      .sort(sortEvents)
      .map((event: Event, index: number) => {
        const getKilometerDifference = (): number => {
          if (typeof event?.stateOdometer === 'number' && typeof eventsToRender[index + 1]?.stateOdometer === 'number') {
            return +event?.stateOdometer - (eventsToRender[index + 1]?.stateOdometer ?? 0);
          }

          return 0;
        };

        return (
          <Record
            key={event.id}
            eventId={event.id}
            title={event.name}
            type={+event.eventType.id as EventType}
            date={event.eventDate}
            kilometersDifference={getKilometerDifference()}
            priceTotal={event.priceTotal ?? 0}
            kilometers={event.stateOdometer ?? 0}
            actualConsumption={
              (
                +event.eventType.id === EVENT_TYPE_REFUELING
              ) ?
                Number(event.refueling?.[0]?.actualConsumption) :
                undefined
            }
            fuelType={event.refueling?.[0]?.fuelType?.fuelName}
            isEven={index % 2 === 0}
          />
        );
      });
  };

  if (eventsLoading && eventsToRender.length === 0) {
    return (
      <Stack spacing={2}>
        <Stack direction="row" spacing={4} alignItems="center">
          <Skeleton variant="rounded" sx={{ minWidth: '40px', height: '40px' }}/>
          <Skeleton variant="rounded" sx={{ width: '100%', height: '72px' }} />
        </Stack>
        <Stack direction="row" spacing={4} alignItems="center">
          <Skeleton variant="rounded" sx={{ minWidth: '40px', height: '40px' }}/>
          <Skeleton variant="rounded" sx={{ width: '100%', height: '72px' }} />
        </Stack>
        <Stack direction="row" spacing={4} alignItems="center">
          <Skeleton variant="rounded" sx={{ minWidth: '40px', height: '40px' }}/>
          <Skeleton variant="rounded" sx={{ width: '100%', height: '72px' }} />
        </Stack>
        <Stack direction="row" spacing={4} alignItems="center">
          <Skeleton variant="rounded" sx={{ minWidth: '40px', height: '40px' }}/>
          <Skeleton variant="rounded" sx={{ width: '100%', height: '72px' }} />
        </Stack>
        <Stack direction="row" spacing={4} alignItems="center">
          <Skeleton variant="rounded" sx={{ minWidth: '40px', height: '40px' }}/>
          <Skeleton variant="rounded" sx={{ width: '100%', height: '72px' }} />
        </Stack>
      </Stack>
    );
  }

  if (eventsError) {
    return (
      <div>
        <ErrorIcon />
      </div>
    );
  }

  if (eventsToRender.length === 0) {
    return (
      <Stack mt={6} spacing={4} alignItems="center">
        <QuoteBlock>
          {
            isWidget ? 
              t('components.recordsNoData.forDevice') :
              (
                tags.length > 0 || isFilterUsed(selections?.selections) ?
                  t('components.recordsNoData.forFilters') :
                  t('components.recordsNoData.forDevice')
              )
          }
        </QuoteBlock>
      </Stack>
    );
  }

  if (isWidget) {
    return (
      <RecordListBlock>
        {renderEvents()}
      </RecordListBlock>
    );
  }

  const hasMore = !isWidget 
    && (events?.eventsByDeviceAndFiltersAndTags ? Boolean(!events?.eventsByDeviceAndFiltersAndTags?.isLast) : true);

  return (
    <>
      <InfiniteScroll
        dataLength={eventsToRender.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<Loader size={20} />}
        style={{ overflow: 'hidden' }}
      >
        <RecordListBlock>
          {renderEvents()}
        </RecordListBlock>
      </InfiniteScroll>
      <ScrollButton />
    </>
  );
};

export default RecordList;
