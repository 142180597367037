import { styled } from '@mui/material';
import { Box, Stack, Typography } from '@mechis/elements';

export const ControlBlock = styled(Box, {
  name: 'Attachments',
  slot: 'ControlBlock',
})(() => ({
  // todo:
}));

export const ContentBlockFunctions = styled(Stack, {
  name: 'Attachments',
  slot: 'ContentBlockFunctions',
})(({ theme }) => ({
  borderBottom: '1px dashed lightgrey',
  paddingBottom: theme.spacing(1),
}));

export const ContentBlockFilters = styled(Stack, {
  name: 'Attachments',
  slot: 'ContentBlockFilters',
})(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  // paddingBottom: theme.spacing(2),
  borderBottom: '1px dashed lightgrey',
}));

export const GridItemHeadline = styled(Typography, {
  name: 'Attachments',
  slot: 'GridItemHeadline',
})(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100px',
  fontSize: '0.75em',
}));

