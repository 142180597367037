import React, { FC } from 'react';
import { IMaintenanceProps } from '../types';
import { Stack } from '@mechis/elements';
import useMaintenance from '@hooks/useMaintenance';
import { ISimpleTableData } from '@mechis/blocks/SimpleTable/types';
import FormBlock from '@mechis/blocks/FormBlock';
import SimpleTable from '@mechis/blocks/SimpleTable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IntervalTitle from './components/IntervalTitle';
import OperationLabel from './components/OperationLabel';

const Preview: FC<IMaintenanceProps> = ({ id, onUpdate }) => {
  const { eventIntervals } = useMaintenance({ eventId: id });
  return (
    <>
      <FormBlock
        headline="Provedené úkony"
        btnTitle="Upravit"
        onSubmit={() => onUpdate('maintenance')}
      >
        <Stack spacing={2}>
          {eventIntervals?.map((interval: any) => {
            // eslint-disable-next-line
            const operations = interval?.operation?.map((operation: any) => {
              return {
                label: <OperationLabel name={operation.name} type={operation.operationType} />,
                value: <CheckCircleIcon color="secondary" />,
              };
            });
            return (
              <SimpleTable
                key={interval.id}
                title={<IntervalTitle name={interval.name} periodDay={interval.periodDay} periodDistance={interval.periodDistance} />}
                data={operations as ISimpleTableData[]}
              />
            );
          })}
        </Stack>
      </FormBlock>
    </>
  );
};

export default Preview;
