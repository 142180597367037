import { addMonths, differenceInDays, addDays, differenceInMonths } from 'date-fns';

export const getDaysFromMonths = (months: number): number => {
  const today = new Date();
  const futureDate = addMonths(today, months);
  const daysDifference = differenceInDays(futureDate, today);

  return daysDifference;
};

export const getMonthsFromDays = (days: number): number => {
  const today = new Date();
  const futureDate = addDays(today, days);
  const monthsDifference = differenceInMonths(futureDate, today);

  return monthsDifference;
};
