import React from 'react';
import { useAttachments, AttachmentsControl } from '@mechis/sections/Attachments';
import {
  Stack,
  Box,
  IconButton,
  Button,
  CircularProgress,
} from '@mechis/elements';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

const AttachmentsActionsEventDetail = () => {
  const {
    selectedFiles,
    setSelectedFiles,
    onCreateEventAttachments,
    isUploading,
  } = useAttachments();

  return (
    <Stack
      direction="row"
      alignItems="end"
      justifyContent="space-between"
      sx={{ width: '100%', p:2 }}
      spacing={8}
    >
      <Box sx={{ flexGrow: 1 }}>
        <AttachmentsControl>
          <IconButton
            size="large"
            sx={{
              border: '1px dashed lightgrey',
              marginTop: '16px',
              pointerEvents: 'none',
            }}
          >
            <DriveFolderUploadIcon />
          </IconButton>
        </AttachmentsControl>
      </Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setSelectedFiles([])}
          sx={{ opacity: selectedFiles.length === 0 ? '0' : 1 }}
        >
          Reset
        </Button>
        <Button
          color="secondary"
          variant={selectedFiles.length === 0 ? 'outlined' : 'contained'}
          disabled={selectedFiles.length === 0 || isUploading}
          onClick={onCreateEventAttachments}
        >
          {isUploading ? <CircularProgress size={22} /> : 'Nahrát'}
        </Button>
      </Stack>
    </Stack>
  );
};

export default AttachmentsActionsEventDetail;

