import React from 'react';
import { AttachmentsControl } from '@mechis/sections/Attachments';
import {
  Stack,
  Box,
  Typography,
  Paper,
  IconButton,
} from '@mechis/elements';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

const AttachmentsContentControl = () => {
  return (
    <Stack sx={{ 
      minHeight: '70vh',
      justifyContent: 'space-around',
      alignItems: 'center',
    }}>
      <Box mx="auto" maxWidth={220}>
        <AttachmentsControl>
          <Paper variant="outlined">
            <Box p={3} textAlign="center">
              <Typography
                variant="body2"
                sx={{ opacity: 0.8 }}
              >
                <small>Momentálně nemáte připojenou žádnou přílohu</small>
              </Typography>
              <IconButton
                size="large"
                sx={{
                  border: '1px dashed lightgrey',
                  marginTop: '16px',
                  pointerEvents: 'none',
                }}
              >
                <DriveFolderUploadIcon sx={{ fontSize: '32px' }} />
              </IconButton>
            </Box>
          </Paper>
        </AttachmentsControl>
      </Box>
    </Stack>
  ); 
};

export default AttachmentsContentControl;
