import colors from './colors';

const palette = {
  mode: 'light',
  common: {
    white: colors.white,
    lightGrey: colors.lightGrey[500],
  },
  primary: {
    light: colors.primary[300],
    // main: colors.primary[500],
    main: '#035133',
    dark: '#035133',
  },
  secondary: {
    light: colors.secondary[350],
    // main: colors.secondary[500],
    main: '#01ab6d',
    dark: colors.secondary[600],
    contrastText: colors.white,
  },
  error: {
    light: colors.red[300],
    main: colors.red[500],
    dark: colors.red[600],
    contrastText: colors.white,
  },
  warning: {
    light: colors.orange[300],
    main: colors.orange[500],
    dark: colors.orange[600],
    contrastText: colors.white,
  },
  success: {
    light: colors.green[300],
    main: colors.green[500],
    contrastText: colors.white,
    dark: colors.green[600],
  },
  info: {
    light: colors.blue[300],
    main: colors.blue[500],
    dark: colors.blue[600],
    contrastText: colors.white,
  },
  white: {
    main: colors.white,
    dark: colors.white,
    contrastText: colors.white,
  },
  background: {
    main: colors.grey[300],
    default: colors.lightGrey[300],
    warning: colors.orange[500],
    success: colors.green[500],
    error: colors.red[500],
    navigationBg: colors.primary[100],
  },
  text: {
    headline: colors.grey[550],
    sectionHeadline: colors.grey[300],
    paragraph: colors.grey[400],
  },
  divider: colors.grey[200],
  backdrop: colors.grey[400],
  action: {
    hoverOpacity: 0.04,
    selectedOpacity: 0.1,
  },
};

export default palette;
