import React, { FC, useEffect } from 'react';
import { Box, Stack } from '@mechis/elements';
import Expenses from '@mechis/sections/Expenses';
import {
  ExpensesByEventDocument,
  TagsOrderedByUsageDocument,
  useExpensesByEventQuery,
  useUpdateExpenseEventMutation,
} from '@state/mechis-backend/generated/schema';
import Loader from '@mechis/blocks/Loader';
import { MAX_EXPENSES_FOR_LIST_IN_DETAIL_OF_EVENT, TAGS_BY_USAGE_LIMIT } from '@configurations/constants/app';
import useExpense from '@hooks/useExpense';
import { IExpense } from '@mechis/sections/Selections/TagFilters/types';
import _ from 'lodash';
import Button from '@mechis/elements/Button';
import snackbarCacheControl from '@state/mutations/snackbar';
import { useTranslation } from 'react-i18next';
import { IExpensesEditProps } from '../types';

const Edit: FC<IExpensesEditProps> = ({ eventId, onBack }) => {
  const { expenses, prices, priceTotal, resetUseExpenseState } = useExpense();
  const { setSnackbar } = snackbarCacheControl;
  const { t } = useTranslation();

  useEffect(() => {
    return () => resetUseExpenseState();
  }, []);

  const { data: expensesInfo, loading: expensesInfoLoading, error: expensesInfoError } = useExpensesByEventQuery({
    variables: {
      eventId: eventId,
      offset: 0,
      limit: MAX_EXPENSES_FOR_LIST_IN_DETAIL_OF_EVENT,
    },
  });

  const [ updateExpenseEventMutation ] = useUpdateExpenseEventMutation();

  const onExpenseUpdate = async () => {
    try {
      await updateExpenseEventMutation({
        variables: {
          event: {
            id: eventId,
            priceTotal: priceTotal,
            expense: expenses.map((expense: IExpense) => {
              return {
                id: expense.existingExpenseId
                  ? Number(expense.existingExpenseId)
                  : undefined,
                tag: {
                  id: Number(expense.id),
                },
                price: _.filter(prices, { tagId: expense.tagId })[0].price,
              };
            }),
          },
        },
        refetchQueries: [
          {
            query: ExpensesByEventDocument,
            variables: {
              eventId: eventId,
              offset: 0,
              limit: MAX_EXPENSES_FOR_LIST_IN_DETAIL_OF_EVENT,
            },
          },
          {
            query: TagsOrderedByUsageDocument,
            variables: {
              limit: TAGS_BY_USAGE_LIMIT,
            },
          },
        ],
        onCompleted: () => {
          setSnackbar('success', 'EVENT_UPDATE_SUCCESSFUL');
          onBack();
        },
      });
    } catch (error) {
      setSnackbar('error', 'EVENT_UPDATE_ERROR');
    }
  };

  if (expensesInfoLoading) {
    return <Loader />;
  }

  if (expensesInfoError) {
    return null;
  }

  return (
    <Box>
      <Expenses existedExpensesInfo={expensesInfo} />
      <Stack justifyContent="center" mt={2} direction={'row'} spacing={2}>
        <Button variant="text" color="secondary" onClick={onBack}>
          {t('techDetail.updateEvent.expenses.back')}
        </Button>
        <Button variant="contained" color="secondary" onClick={() => onExpenseUpdate()}>
          {t('techDetail.updateEvent.expenses.update')}
        </Button>
      </Stack>
    </Box>
  );
};

export default Edit;
