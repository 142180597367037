import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActualOdometerStateByDeviceIdDocument,
  MaintenanceIntervalsByDeviceDocument,
  TagsOrderedByUsageDocument,
  useDeleteEventMutation,
  useRecordLayoutInfoByEventIdQuery,
} from '@state/mechis-backend/generated/schema';
import {
  EVENT_TYPE_EXPENSE,
  EVENT_TYPE_MAINTENANCE,
  EVENT_TYPE_REFUELING,
  // todo: fix eventType to match EventType type
  // eslint-disable-next-line
  EventType,
} from '@configurations/constants/eventTypes';
import ErrorIcon from '@mui/icons-material/Error';
import Loader from '@mechis/blocks/Loader';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Stack,
} from '@mechis/elements';
import snackbarCacheControl from '@state/mutations/snackbar';
import useExpense from '@hooks/useExpense';

import useHeader from '@hooks/useHeader';
import Base from './Base';
import Description from './Description';
import ExpensesPreview from './expenses/Preview';
import MaintenancePreview from './maintenance/Preview';
import RefuelingPreview from './refueling/Preview';
import { UpdateEventType } from './types';
import { useAttachments } from '@mechis/sections/Attachments';
import { TAGS_BY_USAGE_LIMIT } from '@configurations/constants/app';
import useTech from '@hooks/useTech';

const EventDetail: FC = () => {
  useHeader({
    headline: 'appBar.headline.record',
  });

  const { id } = useParams();
  const { setSnackbar } = snackbarCacheControl;
  const [ eventType, setEventType ] = useState<number>();
  const [ isDeleteOpened, setIsDeleteOpened ] = useState<boolean>(false);
  const navigate = useNavigate();
  const { resetUseExpenseState } = useExpense();
  const { setSelectedFiles, setIsModalOpen } = useAttachments();
  const { techId } = useTech();

  const {
    data: eventInfo,
    loading: eventInfoLoading,
    error: eventInfoError, 
  } = useRecordLayoutInfoByEventIdQuery({
    variables: {
      eventId: Number(id),
    },
  });

  const [ deleteEvent, {
    error: errorDeleteEvent, loading: loadingDeleteEvent,
  } ] = useDeleteEventMutation({
    refetchQueries: [
      {
        query: TagsOrderedByUsageDocument,
        variables: {
          limit: TAGS_BY_USAGE_LIMIT,
        },
      },
      {
        query: MaintenanceIntervalsByDeviceDocument,
        variables: {
          deviceId: techId,
        },
      },
      {
        query: ActualOdometerStateByDeviceIdDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });
  
  useEffect(() => {
    resetUseExpenseState();
  }, []);

  useEffect(() => {
    return () => {
      setSelectedFiles([]);
      setIsModalOpen(false);
    };
  }, []);

  useEffect(() => {
    if (eventInfo && !eventInfoLoading && !eventInfoError) {
      setEventType(Number(eventInfo.event?.eventType?.id));
    }
  }, [ eventInfo, eventInfoLoading, eventInfoError ]);

  const onDeleteEvent = async () => {
    await deleteEvent({
      variables: {
        deleteEventId: Number(id),
      },
    });
    errorDeleteEvent ? setSnackbar('error', 'EVENT_DELETE_ERROR') : setSnackbar('success', 'EVENT_DELETE_SUCCESSFUL');
    navigate(-1);
  };

  const handleUpdateEvent = (type: UpdateEventType) => navigate(`update/${type}`);

  if (eventInfoLoading) {
    return (
      <Loader />
    );
  }

  if (eventInfoError) {
    return (
      <div>
        <ErrorIcon />
      </div>
    );
  }

  return (
    <Stack>
      <Dialog
        open={isDeleteOpened}
        onClose={() => setIsDeleteOpened(false)}
        maxWidth="xs"
      >
        <Box p={2}>
          <DialogContent>
            <Typography>Opravdu chcete záznam odstranit?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="contained"
              onClick={onDeleteEvent}
            >
              Ano, odstranit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Base
        id={id || ''}
        eventType={eventType as EventType}
      >
        <Button
          disabled={loadingDeleteEvent}
          onClick={() => setIsDeleteOpened(true)}
          variant="contained"
          color="error"
          size="small"
          sx={{
            alignSelf: 'end',
            mt: -4,
          }}
        >
          Odstranit záznam
        </Button>
      </Base>
      <Stack spacing={4} mt={2} mb={4}>
        {eventType === EVENT_TYPE_EXPENSE && <ExpensesPreview id={id || ''} onUpdate={handleUpdateEvent} />}
        {eventType === EVENT_TYPE_REFUELING && <RefuelingPreview id={id || ''} onUpdate={handleUpdateEvent} />}
        {eventType === EVENT_TYPE_MAINTENANCE && (
          <>
            <ExpensesPreview id={id || ''} onUpdate={handleUpdateEvent} />
            <MaintenancePreview id={id || ''} onUpdate={handleUpdateEvent} />
          </>
        )}
        <Description id={id || ''} />
      </Stack>
      
    </Stack>
  );
};

export default EventDetail;
