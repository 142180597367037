import React, { FC, useEffect, useState } from 'react';
import { useAttachments } from '@mechis/sections/Attachments';
import { IAttachmentsLightboxItemProps } from '@mechis/sections/Attachments';

const AttachmentsLightboxItem: FC<IAttachmentsLightboxItemProps> = ({
  storageName,
}) => {
  const { getFileURL } = useAttachments();
  const [ source, setSource ] = useState<string | null>(null);

  useEffect(() => {
    const fetchAvatarUrl = async () => {
      try {
        const url = await getFileURL(storageName);
        setSource(url);
      } catch (error) {
        console.log('Error fetching avatar URL:', error);
        setSource(null);
      }
    };
    fetchAvatarUrl();
  }, [ storageName ]);

  return (
    <img
      // todo: put default mechis image here instead of empty string
      // todo: it can be some mechis loader (pulse, mui skeleton like)
      src={source || ''}
      alt="todo: alt"
      style={{ width: '100%' }}
    />
  );
};

export default AttachmentsLightboxItem;
